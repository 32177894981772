import React, {useState, useContext} from 'react';
import './Profile.scss';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import {Modal, Form, CustomInput, ModalFooter, ModalBody, Row} from 'reactstrap';
import userProfileImg from '../../assets/images/png/user-dummy-pic1.png';
import {useQuery} from '@apollo/client';
import Loader from '../../components/loader/Loader';
// import { Redirect } from 'react-router-dom';
// import AuthContext from '../../context/authContext';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import {Mutation} from '@apollo/react-components';
import query from '../../query';
import messages from '../../config/constant/messages';
import InputSelect from '../../components/inputSelectBox/InputSelect';
import SearchLocationInput from './SearchLocationInput';
import LoanDetailContext from '../../context/loanDetailContext';

const Profile = props => {
  // const auth = useContext(AuthContext);
  const loanDetail = useContext(LoanDetailContext);

  const loanId = loanDetail.loan_id;

  const {loading, data, refetch} = useQuery(query.getCustomerDetail);

  const [customerInfo, setCustomerInfo] = useState({});

  const [modalStatus, setModalStatus] = useState(false);

  const [resultStatus, setResultStatus] = useState(false);

  const [formErrors, setFormError] = useState({
    address1: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    cellNumber: '',
    email: '',
  });

  const [showModal, setShowModal] = useState(false);

  const [building, updateBuilding] = useState('');

  const [address2, setAddress2] = useState('');

  const toggle = () => {
    setShowModal(!showModal);
    if (data) {
      setCustomerInfo(data.customer);
      updateBuilding(data.customer.address2 === null ? '' : data.customer.address2.split(' ')[0]);
      setAddress2(data.customer.address2 === null ? '' : data.customer.address2.split(' ')[1]);
    }
  };

  if (data) {
    var paperFee = loanId !== '' ? Number(data.loans[0].paperStatementFee.replace(/\$|,/g, '')) : 0
  }

  // if (error) {
  //   // localStorage.removeItem('user_auth_data');
  //   // let message = 'error.networkError.result.error.message';
  //   // auth.login();
  //   // errorMessage.error(message);
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/',
  //       }}
  //     />
  //   );
  // }

  const handleChange = e => {
    e.preventDefault();
    assignValues(e);
  };

  const assignValues = e => {
    const {name, value} = e.target;
    setCustomerInfo({...customerInfo, [name]: value});
    let formError = formErrors;
    switch (name) {
      case 'address1':
        formError.address1 = value.length < 1 ? 'required' : '';
        break;
      case 'city':
        formError.city = value.length < 1 ? 'required' : '';
        break;
      case 'state':
        formError.state = value.length < 1 ? 'required' : '';
        break;
      case 'postalCode':
        formError.postalCode = value.length < 1 ? 'required' : '';
        break;
      case 'phoneNumber':
        formError.phoneNumber = !validPhoneNumber(value) ? 'invalid format' : '';
        break;
      case 'cellNumber':
        formError.cellNumber = !validPhoneNumber(value) ? 'invalid format' : '';
        break;
      case 'email':
        formError.email = value.length < 1 ? 'required' : '';
        break;
      default:
        break;
    }
    setFormError(formError);
  };

  const validPhoneNumber = number => {
    if (number.replace(/[^\d]/g, '').length > 10) {
      return false;
    }
    const numberRegex = new RegExp(/^[\d ()-]+$/);
    return numberRegex.test(number);
  };

  const validate = e => {
    e.preventDefault();
    let status = true;

    if (
      customerInfo.address1 === '' ||
      customerInfo.city === '' ||
      customerInfo.state === '' ||
      customerInfo.postalCode === '' ||
      customerInfo.cellNumber === '' ||
      customerInfo.email === ''
    ) {
      status = 'false';
      setModalStatus({message: messages.profile.required, color: 'danger'});
    }

    if (!validPhoneNumber(customerInfo.cellNumber)) {
      status = 'false';
      setModalStatus({message: 'Invalid Cell Number', color: 'danger'});
    }

    if (!validPhoneNumber(customerInfo.phoneNumber)) {
      status = 'false';
      setModalStatus({message: 'Invalid Phone Number', color: 'danger'});
    }

    return status;
  };

  const handleSubmit = async (e, updateProfile) => {
    e.preventDefault();
    if (validate(e) === true) {
      try {
        let address = building.toUpperCase() + ' ' + address2;
        const {data: mutationData} = await updateProfile({
          variables: {
            address_1: customerInfo.address1,
            address_2: address,
            city: customerInfo.city,
            state: customerInfo.state,
            postalCode: customerInfo.postalCode,
            phoneNumber: customerInfo.phoneNumber,
            cellNumber: customerInfo.cellNumber,
            paperStatement: loanId !== "" ? customerInfo.paperStatement : false,
            email: customerInfo.email,
          },
        });
        if (mutationData) {
          setResultStatus({message: messages.profile.updated, color: 'success'});
          setShowModal(!showModal);
        }
      } catch (err) {
        setModalStatus({
          message: err.graphQLErrors.length
            ? err.graphQLErrors.map(e => e.message)
            : messages.loginErrors.default,
          color: 'danger',
        });
      }
    }
  };

  const allowedDesignators = [
    '',
    'APT',
    'BLDG',
    'BSMT',
    'LOT',
    'STE',
    'TRLR',
    'UNIT',
    'DEPT',
    'FL',
    'FRNT',
    'HNGR',
    'KEY',
    'LBBY',
    'LOWR',
    'OFC',
    'PH',
    'PIER',
    'REAR',
    'RM',
    'SIDE',
    'SLIP',
    'SPC',
    'STOP',
    'UPPR',
  ];

  const resetModal = () => {
    setModalStatus(null);
    setFormError({
      address1: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
      cellNumber: '',
      email: '',
    });
    refetch();
  };

  const autocompleteAddress = (data) => {

    if (data['country'] === 'United States') {
      let addressFields = {
        address1: `${data['street_number']} ${data['route']}`,
        city: `${data['locality']}`,
        state: data['administrative_area_level_1'],
        postalCode: data['postal_code']
      }
      setCustomerInfo(prev => ({...prev, ...addressFields}))
    }
  }

  return (
    <div className="align-element-top ">
      <div className="user-profile-bg"></div>
      <div className="user-profile-main mb-5">
        <img src={userProfileImg} className="user-profile-img shadow-custom" alt="Profile"/>
        {loading && <Loader/>}
        <div className="d-flex justify-content-center">
          {resultStatus && (
            <StatusMessage
              color={resultStatus.color}
              value={resultStatus.message}
              timeout={true}
              visible={true}
            />
          )}
        </div>
        {data && (
          <div className="user-details">
            <h5 className="below-headint-text">
              <b>
                {data.customer.firstName}&nbsp;{data.customer.lastName}
              </b>
            </h5>
            <p className="mb-0">{data.customer.email}</p>
            <div className="d-flex justify-content-center mb-0">
              {data.customer.phoneNumber && <p>p: {data.customer.phoneNumber} &nbsp;</p>}| &nbsp;
              {data.customer.cellNumber && <p>c: {data.customer.cellNumber}</p>}
            </div>
            <p className="mb-0">
              {data.customer.address1}, {data.customer.address2}
            </p>
            <p className="mb-0">
              {data.customer.city}, {data.customer.state}&nbsp;
              {data.customer.postalCode}
            </p>
            {data.customer.paperStatement === false && paperFee !== 0 && (
              <h5 className="mt-5">
                You’ve gone paperless, saving you {data.loans[0].paperStatementFee}/m
              </h5>
            )}
            <Button
              title="Update My Profile"
              className="update btn btn-custom-primary mt-4"
              onClick={toggle}
            >
              <b>Update My Profile</b>
            </Button>
          </div>
        )}
      </div>
      {/* //Update modal */}
      <Modal isOpen={showModal} onClosed={resetModal} className="modal-l">
        <ModalBody className="pt-lg-4 px-lg-5 p-4 pb-2">
          <h4 className="text-theme mb-2" id="UpdateCustomerLabel">
            <b>Update Profile Information</b>
          </h4>
          {data && (
            <React.Fragment>
              {modalStatus && (
                <StatusMessage
                  color={modalStatus.color}
                  value={modalStatus.message}
                  allowClose={true}
                />
              )}
              <div className="border px-4 pt-2 pt-lg-4">
                <div className="mb-4">
                  <h4 className="">
                    <b>
                      {data.customer.firstName}&nbsp;{data.customer.lastName}
                    </b>
                  </h4>
                </div>
                <Row>
                  <div className="col-md-10 text-left">
                    <b>
                      <p className="mb-0">Address:</p>
                    </b>
                  </div>
                  <div className="col-md-6">
                    <div className="input-column position-relative">
                      <SearchLocationInput sendLocation={autocompleteAddress}>
                        <InputText
                          name="address1"
                          type="text"
                          value={customerInfo.address1}
                          placeholder="Address1"
                          className="form-control input-type-text"
                          id="address"
                          onChange={handleChange}
                          errorsMessage={formErrors ? formErrors.address1 : ''}
                          autoComplete="on"
                        />
                      </SearchLocationInput>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <InputSelect
                      name="buildingType"
                      type="select"
                      id="buildingType"
                      className="form-control input-type-text select-input mb-sm-4"
                      value={building}
                      onChange={e => updateBuilding(e.target.value)}
                      options={allowedDesignators}
                    ></InputSelect>
                  </div>
                  <div className="col-md-4">
                    <InputText
                      name="address2"
                      id="address2"
                      type="text"
                      className="form-control input-type-text  "
                      value={address2}
                      placeholder="Address2"
                      onChange={e => setAddress2(e.target.value)}
                      errorsMessage={formErrors ? formErrors.address2 : ''}
                    />
                  </div>
                </Row>
              <Row>
                <div className="col-md-5">
                  <div className="input-column position-relative">
                    <InputText
                      name="city"
                      type="text"
                      placeholder="City"
                      value={customerInfo.city}
                      className="form-control input-type-text"
                      id="city"
                      onChange={handleChange}
                      errorsMessage={formErrors ? formErrors.city : ''}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="input-column position-relative">
                    <InputText
                      name="state"
                      type="text"
                      className="form-control input-type-text"
                      placeholder="State"
                      value={customerInfo.state}
                      onChange={handleChange}
                      errorsMessage={formErrors ? formErrors.state : ''}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-column position-relative">
                    <InputText
                      name="postalCode"
                      type="text"
                      className="form-control input-type-text"
                      value={customerInfo.postalCode}
                      placeholder="Postal Code"
                      onChange={handleChange}
                      errorsMessage={formErrors ? formErrors.postalCode : ''}
                    />
                  </div>
                </div>
              </Row>
              {/* <!-- end of third row columns --> */}
              <Row>
                <div className="col-md-10 text-left mt-3">
                  <b>
                    <p className="mb-0">Phone numbers:</p>
                  </b>
                </div>
                <div className="col-md-4">
                  <div className="input-column position-relative">
                    <InputText
                      name="phoneNumber"
                      type="text"
                      className="form-control input-type-text"
                      value={customerInfo.phoneNumber}
                      placeholder="Phone Number"
                      onChange={handleChange}
                      errorsMessage={formErrors ? formErrors.phoneNumber : ''}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-column position-relative">
                    <InputText
                      name="cellNumber"
                      type="text"
                      className="form-control input-type-text"
                      value={customerInfo.cellNumber}
                      placeholder="Cellphone Number"
                      onChange={handleChange}
                      errorsMessage={formErrors ? formErrors.cellNumber : ''}
                    />
                  </div>
                </div>
              </Row>

              <Row>
                <div className="col-md-10 mt-3 text-left">
                  <b>
                    <p className="mb-0">Email Address:</p>
                  </b>
                </div>
                <div className="col-md-6">
                  <div className="input-column position-relative">
                    <InputText
                      name="email"
                      type="text"
                      className="form-control input-type-text"
                      value={customerInfo.email}
                      onChange={handleChange}
                      placeholder="Email"
                      errorsMessage={formErrors ? formErrors.email : ''}
                    />
                  </div>
                </div>
              </Row>
              {loanId !== "" && (
                <div className="check_this mt-3">
                  <div className="form-group form-check pl-lg-0">
                    <CustomInput
                      type="checkbox"
                      id="paperStatement"
                      name="paperStatement"
                      className="form-check-input pl-lg-0 text-left"
                      label={
                        ' Check this box if you would like to receive a monthly statement in the regular mail (' +
                        data.loans[0].paperStatementFee +
                        ' fee)'
                      }
                      defaultChecked={customerInfo.paperStatement}
                      onChange={() =>
                        setCustomerInfo({
                          ...customerInfo,
                          paperStatement: !customerInfo.paperStatement,
                        })
                      }
                      value={customerInfo.paperStatement}
                      inline
                    />
                  </div>
                </div>
              )}
            </div>
            </React.Fragment>
            )}
        </ModalBody>
        <Mutation mutation={query.updateProfile}>
          {(updateProfile, {data, loading, error}) => (
            <Form onSubmit={e => handleSubmit(e, updateProfile)}>
              {loading && <Loader/>}
              <ModalFooter className="justify-content align-items-center no-border">
                <Button
                  type="button"
                  data-dismiss="modal"
                  title="Close"
                  className="btn btn-custom-primary"
                  onClick={toggle}
                ></Button>
                <Button
                  type="submit"
                  title="Update"
                  hvrtitle="Update Button"
                  className="btn btn-custom-primary alert-button text-white mt-0"
                ></Button>
              </ModalFooter>
            </Form>
          )}
        </Mutation>
      </Modal>
    </div>
  );
};

export default Profile;
