import React, { useState, useRef, useContext } from 'react';
import LoanDetailContext from '../../context/loanDetailContext';
import './headerNavbar.scss';
import { Navbar } from 'reactstrap';
import LogoWhiteImg from '../logo/LogoWhite';
import User from '../user/User';
import Logout from '../logout/Logout';
import MenuItem from './MenuItems';
import Menu from './Menu';
import { outsideClick } from '../outsideClick';
import AuthContext from "../../context/authContext";

const HeaderNavbar = () => {
  const { status: authStatus } = useContext(AuthContext);
  const loanDetail = useContext(LoanDetailContext);
  const loanId = loanDetail.loan_id;
  const userName = localStorage.getItem('user_auth_name');

  const [state, setState] = useState({
    profileMenuOpen: false,
  });

  const wrapperRef = useRef(null);

  const handleClickOutside = () => {
    setState({
      profileMenuOpen: false,
    })
  }

  const handleMenuClick = () => {
    setState({
      profileMenuOpen: !state.profileMenuOpen,
    });
    outsideClick(wrapperRef, handleClickOutside);
  };

  const handleLinkClick = () => {
    setState({ profileMenuOpen: false });
  };

  const homeProfileItems = [
    {
      name: 'Home',
      url: '/',
      addClass: 'account-user',
    },
    {
      name: 'Profile',
      addClass: 'profile',
      url: '/Profile',
    }
  ]

  const accountMenuItems = [
    {
      addClass: 'account-summary',
      name: 'Account Summary',
      url: '/AccountSummary',
    },
    {
      addClass: 'make-a-payment',
      name: 'Make a Payment',
      url: '/MakePayment',
    },
    {
      addClass: 'payment-methods',
      url: '/PaymentMethods',
      url1: '/PaymentMethods/AddingPaymentMethod',
      name: 'Payment Methods',
    },
    {
      addClass: 'my-coverages',
      url: '/MyCoverage',
      name: 'My Coverages',
    },
    {
      addClass: 'statements-and-notifications',
      url: '/Statements',
      name: 'Statements & Notifications',
    }
  ]

  const logoutMenuItems = [
    {
      name: <Logout title="Logout" />,
      addClass: 'logout',
      url: '/',
    }
  ]

  const menuLabels = loanId ? homeProfileItems.concat(accountMenuItems, logoutMenuItems) : homeProfileItems.concat(logoutMenuItems)

  //Method to display menu items in the navigation bar
  const menuItems = menuLabels.map((val, index) => {
    return (
      <MenuItem
        id={index+1}
        key={index}
        delay={`${index * 0.04}s`}
        onClick={handleLinkClick}
        url={val.url}
        className={val.addClass}
        title={val.name}
      >
        {val.name}
      </MenuItem>
    );
  });

  return (
    <div className={'position-fixed custom-navbar'}>
      <Navbar color="faded shadow-sm" light>
        {/* ////// Logo ////// */}
        <div className="mr-auto ml-0 ml-lg-3">
          <LogoWhiteImg title="Logo" />
        </div>
        {/* /////// User /////// */}
        {authStatus && <div onClick={handleMenuClick}>
          <User title={userName}/>
        </div>}
      </Navbar>
      <Menu open={state.profileMenuOpen} wrapperRef={wrapperRef}>
        {menuItems}
      </Menu>
    </div>
  );
};

export default HeaderNavbar;
