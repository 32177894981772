import React from 'react';
import UserImg from '../../assets/images/png/user-dummy-pic.png';

const User = ({ title }) => {
  return (
    <div className="user-sec mr-0">
      <span>{title}</span>
      <img src={UserImg} alt="User" className="user-icon ml-1" title={title} />
    </div>
  );
};

export default User;
