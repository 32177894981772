import React from 'react';
import LogoWhiteImg from '../../assets/images/logo/logo-w1.png';
import { Link } from 'react-router-dom';

const LogoWhite = ({ title }) => {
  return (
    <Link to="/">
      <img src={LogoWhiteImg} className="img-fluid logo" alt="" title={title} />
    </Link>
  );
};

export default LogoWhite;
