import {Formik, Form, Field, ErrorMessage, getIn, useFormikContext} from 'formik';
import React, {useContext, useState, useEffect} from "react";
import Button from "../button/Button";
import checkImg from "../../assets/images/png/check@2x-1.png";
import LoanDetailContext from "../../context/loanDetailContext";
import {useMutation} from "@apollo/client";
import query from "../../query";
import StatusMessage from "../statusMessage/StatusMessage";
import history from '../../routes/history'
import PaymentDetailContext from "../../context/paymentDetailContext";
import './BankTransferForm.scss'
import Loader from "../loader/Loader";
import messages from "../../config/constant/messages";

const BankTransferForm = (props) => {
  const LoanDetail = useContext(LoanDetailContext);
  const { updatePaymentData, updateMessage, paymentDetail } = useContext(PaymentDetailContext);

  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [paymentMethodId, setPaymentMethodId] = useState();
  const [autopaySelected, setAutopaySelected] = useState(true);
  const [formWithoutContext, setFormWithoutContext] = useState(false);

  const [addBankAccount, {loading}] = useMutation(query.addBankAccount);
  
  const addPaymentManually = (data) => {
    props.addPaymentManually(data)
  }

  useEffect(() => {
    if (paymentDetail && Object.keys(paymentDetail).length === 0 && Object.getPrototypeOf(paymentDetail) === Object.prototype) {
      setFormWithoutContext(true);
    }

    if (paymentMethodId) {
      updatePaymentData({paymentMethodId: paymentMethodId});
      history.push("/MakePayment");
    }
  }, [paymentMethodId])

  const initialValues = {
    loanId: LoanDetail.loan_id,
    bankTransferName: '',
    bankTransferRoutingNumber: '',
    bankTransferRoutingNumberConfirmation: '',
    bankTransferAccountNumber: '',
    bankTransferAccountNumberConfirmation: '',
    bankTransferAccountType: 'Checking',
    formOfPaymentType: 'bank_transfer',
    autopay: autopaySelected
  }
  const validate = (value) => {
    let error;
    if (!value) {
      error = '* required';
    }
    return error;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={(values, { setSubmitting }) => {
      values = {...values, loanId: parseInt(values.loanId)}
      addBankAccount({
        variables: values,
      })
        .then(result => {
          updateMessage('Payment Method Added To Your Account!')
          setSuccessMessage('Payment Method Added To Your Account!')
          setPaymentMethodId(result['data']['postPaymentMethod']['id']);
        })
        .catch(error => {
          if (error) {
            updateMessage(() => error.message);
            setError(error.message);
          }
        });
    }}>
      {
        ({values, isValid, dirty, errors, setValues}) => {
          const validateBankTransferAccountType = (value) => {
            let error;
            if (!value) {
              error = messages.required;
            }

            if (value === 'saving') {
              error = messages.notAcceptSavingsAccount;
            }
            return error;
          }

          const validateBankTransferRoutingNumber = (value) => {
            let error;
            if (value.length !== 9) {
              error = messages.routingNumberLength;
            } else if (!validateAbaRoutingNumber(value)) {
              error = messages.notValidRoutingNumber;
            }
            return error;
          }

          const validateAbaRoutingNumber = (numberStr) =>  {
            const routingNumber = numberStr;
            let n = 0;
            for (let i = 0; i < routingNumber.length; i += 3) {
              n += parseInt(routingNumber.charAt(i), 10) * 3 + parseInt(routingNumber.charAt(i + 1), 10) * 7 + parseInt(routingNumber.charAt(i + 2), 10);
            }
            return n != 0 && n % 10 == 0
          }

          const validateBankTransferRoutingNumberConfirmation = (value) => {
            let error;
            if (value !== values.bankTransferRoutingNumber) {
              error = messages.matchRoutingNumber;
            }
            return error;
          }

          const validateBankTransferAccountNumberConfirmation = (value) => {
            let error;
            if (value !== values.bankTransferAccountNumber) {
              error = messages.matchAccountNumber
            }
            return error;
          }

          const toggleAutopay = () => {
            let curr = values.autopay
            setAutopaySelected(prev => !curr)
            setValues(prev => ({...prev, autopay: !curr}))
          }

          return (
            <Form>
                <img src={checkImg} name="" className={'img-banking'} alt="Check" />
                {loading && <Loader />}
                {error && <StatusMessage color={'danger'} value={error}/>}
                {successMessage && <StatusMessage color={'danger'} value={successMessage}/>}
                <div className={'row m-2'}>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferName"
                           placeholder="Bank Name"
                           className="input-type-text inspectletIgnore no-upscope w-100"
                           validate={validate}
                    />
                    <ErrorMessage name='bankTransferName' component='div' className={'text-red'} />
                  </div>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferAccountType"
                           placeholder="Bank Name"
                           as={'select'}
                           className="select-input inspectletIgnore no-upscope w-100"
                           validate={validateBankTransferAccountType}
                    >
                      <option value="checking">Checking</option>
                      <option value="saving">Saving</option>
                    </Field>
                    <ErrorMessage name='bankTransferAccountType' component='div' className={'text-red'} />
                  </div>
                </div>
                <div className={'row m-2'}>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferRoutingNumber"
                           placeholder="Routing Number"
                           className="input-type-text inspectletIgnore no-upscope w-100"
                           validate={validateBankTransferRoutingNumber}
                    />
                    <ErrorMessage name='bankTransferRoutingNumber' component='div' className={'text-red'} />
                  </div>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferRoutingNumberConfirmation"
                           placeholder="Confirm Routing Number"
                           className="input-type-text inspectletIgnore no-upscope w-100"
                           validate={validateBankTransferRoutingNumberConfirmation}
                    />
                    <ErrorMessage name='bankTransferRoutingNumberConfirmation' component='div' className={'text-red'} />
                  </div>
                </div>
                <div className={'row m-2'}>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferAccountNumber"
                           placeholder="Account Number"
                           className="input-type-text inspectletIgnore no-upscope w-100"
                           validate={validate}
                    />
                    <ErrorMessage name='bankTransferAccountNumber' />
                  </div>
                  <div className={'col-sm-6 col-md-4'}>
                    <Field type="string"
                           name="bankTransferAccountNumberConfirmation"
                           placeholder="Confirm Account Number"
                           className="input-type-text inspectletIgnore no-upscope w-100"
                           validate={validateBankTransferAccountNumberConfirmation}
                    />
                    <ErrorMessage name='bankTransferAccountNumberConfirmation' component='div' className={'text-red'} />
                  </div>
                </div>
                <label className="switch mb-0">
                  <Field type="checkbox" name="autopay" checked={autopaySelected} onChange={toggleAutopay} value={autopaySelected} />
                  <span className="slider round"></span>
                </label>
                <span className="pl-2">
                  <b>Autopay</b>
                </span>
                <div className={'row mt-2'}>
                  <Button title={'Back'} onClick={(e) => addPaymentManually({method: '', tab: '1'})}>Back</Button>
                  <Button type="submit" disabled={!isValid || !dirty} title={formWithoutContext ? 'Submit' : 'Continue'} className={'alert-button text-white'}/>
                </div>
                <div className="col-md-12 col-lg-12">
                  <div class="container-arrow-button" onClick={() => addPaymentManually({method: 'payment_card', tab: '2'})}>
                    <div class="text-arrow">
                      <p>ADD PAYMENT CARD</p>
                    </div>
                    <div class="image-div-arrow">
                      <img 
                        src="https://cdn-icons-png.flaticon.com/512/664/664866.png" 
                        class="img-arrow"
                      />
                    </div>
                  </div>
                </div>
            </Form>
          )
        }
      }
    </Formik>
  )
}

export default BankTransferForm;