import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './AccountMenus.scss';

const AccountMenus = ({ className, handleClick, wrapperRef }) => {
  // To get the list of options in the Menu Tab
  const tabsMenu = [
    {
      id: 1,
      classes: 'account-summary',
      name: 'Account Summary',
      url: '/AccountSummary',
    },
    {
      id: 2,
      classes: 'make-a-payment',
      name: 'Make a Payment',
      url: '/MakePayment',
    },
    {
      id: 3,
      classes: 'payment-methods',
      url: '/PaymentMethods',
      url1: '/PaymentMethods/AddingPaymentMethod',
      name: 'Payment Methods',
    },
    {
      id: 4,
      classes: 'my-coverages',
      url: '/MyCoverage',
      name: 'My Coverages',
    },
    {
      id: 5,
      classes: 'statements-and-notifications',
      url: '/Statements',
      name: 'Statements & Notifications',
    },
  ];

  const defaultActiveMenu = tabsMenu.find(
    data => data.url === window.location.pathname || data.url1 === window.location.pathname
  );
  const tab = defaultActiveMenu && defaultActiveMenu.id;

  const [activated, setActivated] = useState({
    addClass: null,
    links: tab,
  });
  if (activated.links !== tab) {
    setActivated({ links: tab });
  }

  //Method to display the Menu Tab
  const tabList = tabsMenu.map((val, id) => {
    return (
      <Link className="text-center delay" to={val.url} key={id}>
        <li
          className={
            'list-group-item px-2 py-1 my-0 my-md-3 ' + (activated.links === val.id ? 'active' : '')
          }
          id={'Tooltip' + val.id}
        >
          <div id="accMenu" className={'img-fluid account-menu-img p-2 ' + val.classes}></div>
          <span className="account-menu-name">{val.name}</span>
        </li>
      </Link>
    );
  });

  return (
    <ul
      onClick={handleClick}
      ref={wrapperRef}
      className={
        'list-group-custom accounts-menu my-2 my-lg-0 my-2 my-lg-5 list-responsive d-lg-flex justify-content-between ' +
        className
      }
    >
      {tabList}
    </ul>
  );
};

export default AccountMenus;
