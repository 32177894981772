import React, {useEffect, useState, useRef} from "react";
import runtimeEnv from '@mars/heroku-js-runtime-env';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script"); // create script tag
  script.type = "text/javascript";

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url; // load by url
  document.getElementsByTagName("head")[0].appendChild(script); // append to head
};

const formattedObj = (addressObject) => {
  const address = addressObject.reduce((seed, {
    short_name,
    long_name,
    types
  }) => (types.forEach(t => {
    if(t == 'administrative_area_level_1' || t == 'route') {
      seed[t] = short_name
    } else {
      seed[t] = long_name
    }
  }), seed), {});

  return address;
};

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const env = runtimeEnv();

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    // assign autoComplete with Google maps place one time
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {types: ["address"], componentRestrictions: {country: "us"}}
    );
    // specify what properties we will get from API
    autoComplete.setFields(["address_components", "formatted_address", "geometry", "adr_address"]);
    // add a listener to handle when the place is selected
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace(); // get place from google api
    const query = addressObject.address_components;
    const formattedQuery = formattedObj(addressObject.address_components);
    updateQuery(addressObject.formatted_address.split(',')[0]);
    props.sendLocation(formattedQuery)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  return (
    <div>
      <input
        className="form-control input-type-text"
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder="Enter Address"
        value={query}
      />
    </div>
  )
}

export default SearchLocationInput;
