import React from 'react';
import { Container } from 'reactstrap';

const Privacy = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="align-element-top ">
        <h1>Privacy Policy</h1>
        <div>
          <p>
            Line 5, LLC puts our customers first. We offer a complimentary quote for protection plan
            financing. The information gathered for this quote is used to give you the most accurate
            quote possible. Personal information such as your name, address, phone number(s), and
            email address is stored in our secure database and is used for any customer care issues
            that may arise.
          </p>
          <p>
            By viewing this website or otherwise using Line 5 Services, you agree to these
            conditions.
          </p>
          <p>
            When you use Line 5 Services you remain subject to the terms and conditions set forth in
            the Line 5 Dealer Agreement ("Dealer Agreement"). If these Conditions of Use are
            inconsistent with the Dealer Agreement, the Dealer Agreement will control.
          </p>
          <p>
            LINE 5 reserves the right to make changes to its website, policies, Services, and these
            Conditions of Use at any time without notice.
          </p>
        </div>
        <div>
          <h2>PERSONAL INFORMATION PRIVACY</h2>
          <p>
            This summary outlines our privacy commitment to you including how we collect and use
            your information, how we protect it, and who can see it. Line 5’s policy includes:
          </p>
          <ul>
            <li>We will not sell your information to third parties</li>
            <li>
              Any third parties who perform service for us are required to safeguard customer
              information
            </li>
            <li>We extensively secure and limit access to your information</li>
            <li>We thoroughly protect information about current, potential and former customers</li>
          </ul>
          <p>
            Line 5 respects your concerns about privacy. This privacy policy discloses our
            information gathering and dissemination practices.
          </p>
          <ul>
            <li>
              <b>Collection of Personal Information:</b> Line 5, LLC collects information that
              identifies you specifically, such as your name, physical address, email addresses,
              phone numbers, social security number, credit history and other "personal information"
              when voluntarily submitted. We receive and store any information that you choose to
              enter on or through this website, including any submissions you make or content you
              provide though an installment contract, dealer agreement and any other documentation
              provided to Line 5.
            </li>
            <li>
              <b>Disclosure of Personal Information:</b> Your information will only be disclosed as
              permitted or required by law. Collected data is maintained in your financial records
              with us. We use this information to process and service your account; and other ways
              as directed by you. We may also disclose it to organizations as necessary to perform
              transactions you request or authorize. All of collected personal information is
              safeguarded – whether a current, former or potential customer.
            </li>
          </ul>
          <h2>SOCIAL SECURITY NUMBER</h2>
          <p>
            Line 5, LLC collects Social Security numbers as necessary to support state and federal
            legal requirements, to provide products or services to you and to meet needs within our
            Human Resources unit. To support these requirements, we restrict the access to Social
            Security numbers only to employees, agents, service providers, and government entities
            that require access to Social Security numbers to meet these purposes. We strive to
            protect Social Security numbers from unlawful disclosure and keep them confidential by
            maintaining administrative, technical, and physical safeguards that comply with
            applicable state and federal regulations.
          </p>
          <h2>IP ADDRESS</h2>
          <p>
            We log all connections to our web servers, including your IP address. An IP address is
            NOT personally identifiable information, but general information about your location,
            connection to the Internet, and your Internet Service Provider. We use your IP address
            to help diagnose problems with our server and to administer this site. Additionally, we
            will use your IP address to track your navigation of the site and we may use your
            navigation history in current and future marketing efforts to you and to others.
          </p>
          <h2>COOKIES</h2>
          <p>
            "Cookies" are small pieces of information stored in a user's browser that give that user
            a unique identification. This site MAY insert cookies into your browser that identify
            you as a previous user of the site and contain non-personally identifying information
            regarding you and your navigation the site. In some instances, these cookies may be used
            by us or other Web sites during your future navigation of the internet to target
            specific advertisements to you based on your previous use of this site. This site may
            also read cookies already stored within your browser to identify non-personal
            information about you and communicate this information to other advertisers. All cookies
            are established on a per session basis, and are not permanently stored on your machine.
            For more information about cookies, please visit www.AboutCookies.org.
          </p>
          <h2>SECURITY</h2>
          <p>
            To better protect your personal information, we have implemented specific data security
            procedures. However, no method of transmitting or storing electronic data is ever
            completely secure, and we cannot guarantee that such information will never be accessed,
            used, or released in a manner that is inconsistent with this policy.
          </p>
          <h2>CHANGES TO THIS PRIVACY POLICY</h2>
          <p>
            This privacy policy describes the types of information we currently collect, and the
            ways we use and protect that information. From time to time, we may collect different
            types of information and use that information in different ways - for example, when we
            add features or services. In these cases, we may edit this policy to explain the new
            practices. Because protecting your privacy is very important to us, we do not expect
            major changes in policy.
          </p>
          <h2>CONTACT US</h2>
          <p>
            To remove or change your contact information in our database, or to not receive future
            mailings or other communications, as well as for all other inquiries, please contact us
            at any time using one of the options below:
          </p>
          <ol>
            <li>
              You may Opt out of our email list to remove yourself from receiving any future e- mail
              communication. Note that this process can take up to 10-business days.
            </li>
            <li>
              You can update your communication preferences by logging into your account on
              www.payline5.com or by using one of the methods listed below:
            </li>
            Regular Mail Line 5, LLC P.O. Box 112737 Naples, FL 34108 Phone (844) 775-4635 E-Mail
            customerservice@line5.com Whether you are a current or potential Line 5 customer, your
            information and needs are handled professionally, and above all, ethically. If you have
            any questions regarding this Privacy Policy don't hesitate to contact us!
          </ol>
          <ul>
            <li>
              <b>Regular Mail</b>
              <p>Line 5, LLC P.O. Box 112737 Naples, FL 34108</p>
            </li>
            <li>
              <b>Phone</b>
              <p>(844) 775-4635</p>
            </li>
            <li>
              <b>E-Mail</b>
              <p>customerservice@line5.com</p>
            </li>
          </ul>
          <p>
            Whether you are a current or potential Line 5 customer, your information and needs are
            handled professionally, and above all, ethically. If you have any questions regarding
            this Privacy Policy don't hesitate to contact us!
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Privacy;
