import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AuthContext from '../../context/authContext';

const Logout = ({ title, message, className }) => {
  const { logout } = useContext(AuthContext);

  const logOut = () => {
    localStorage.removeItem('loan_id');
    localStorage.removeItem('user_auth_data');
    logout();
    return <Redirect to="/" />;
  };

  return (
    <Link to="" onClick={logOut} title={title} className={className}>
      Log out
    </Link>
  );
};

export default Logout;
