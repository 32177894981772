import React from 'react';
import Logo from '../../components/logo/Logo';
import { Container, Row, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';

const AddPaymentMethod = () => {
  return (
    <Container>
      <Form className="align-element-center py-1 py-lg-5">
        <div className="col-md-4 offset-md-4 col-12 text-center ">
          <Logo />
          <div className="mt-5">
            <h3 className="mb-0 heading">
              <b>Add a payment method.</b>
            </h3>
            <span className="below-headint-text">
              Line5 requires automatic payments. To complete your account activation, add a payment
              method.
            </span>
          </div>
          <Row className="justify-content-center align-items-center mt-4 mt-lg-5">
            <div className="mr-4">
              <Link to="/AccountActivated" title="Cancel" className="cancel-link">
                Cancel
              </Link>
            </div>
            <div className="login-btn ml-2">
              <Link to="/SelectPaymentType">
                <Button title="Next" hvrtitle="Next Button"></Button>
              </Link>
            </div>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default AddPaymentMethod;
