import React from 'react';
import { Container, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';

const AcountActivated = () => {
  return (
    <Container>
      {/* start form */}
      <Form className="align-element-center py-1 py-lg-5">
        <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-12 text-center">
          {/* Logo component */}
          <Logo />
          {/* Greeting section */}
          <div className="mt-5">
            <h4 className="mb-0 heading">
              <b>Congratulations! Your account has been activated.</b>
            </h4>
            <span className="below-headint-text">Log into your account for the first time.</span>
          </div>
          {/* Input components */}
          <div className="mt-1">
            <InputText
              type="email"
              name="email"
              id="email"
              placeholder="Enter Email"
              className="text-font-size"
            />
            <InputText
              type="password"
              name="password"
              id="password"
              placeholder="Enter Password"
              className="text-font-size"
            />
          </div>
          {/* Buttom and Cancle link */}
          <Row className="justify-content-center align-items-center mt-4 mt-lg-5">
            <div className=" mr-4">
              <Link to="/AccountFound" title="Back" className="cancel-link">
                Back
              </Link>
            </div>
            <div className="login-btn ml-2">
              <Link to="/AddPaymentMethod">
                <Button title="Login" hvrtitle="Login Button"></Button>
              </Link>
            </div>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default AcountActivated;
