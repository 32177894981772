import React from 'react';
import { Row, Container } from 'reactstrap';
import Logout from '../../components/logout/Logout';

const NoLoanErrorPage = () => {

  return (
    <Container>
      <Row className=" page-not-found">
        <div className="col-md-12 col-xs-12 text-center">
          <h1 className="mt-3">The loan has not been funded yet!</h1>
          <h4 className="mt-2">Please wait utill the loan will be funded.</h4>
          <div className="page-not-found-main text-center">
            <div className="page-not-found-circle">
              <div className="body-pageNotFound mb-3">
                <h5>Thank you</h5>
                <Row className="justify-content-center">
                  <Logout className="logout-link text-white shadow-0"/>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};
export default NoLoanErrorPage
