import { useState } from "react";
import PaymentDetailContext from './paymentDetailContext';

const PaymentContextProvider = ({ children }) => {
  const [paymentData, setPaymentData] = useState({});
  const [message, setMessage] = useState();

  const updatePaymentData = (values) => {
    setPaymentData(prev => ({...prev, ...values}));
  }

  const updateMessage = (value) => {
    setMessage(value);
  }

  const contextValue = {
    paymentDetail: paymentData,
    message: message,
    updatePaymentData,
    updateMessage,
  }

  return (
    <PaymentDetailContext.Provider value={contextValue}>
      {children}
    </PaymentDetailContext.Provider>
  );
}

export default PaymentContextProvider;
