import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';

const StatusMessage = props => {
  /*-- Alert message --*/
  const [visible, setVisible] = useState(true);

  const onDismiss = () => {
    setVisible(false);}

  const timeout = () => {
    setTimeout(() => {
      setVisible(false);
    }, 10000)
  }
  /*-- End Alert message --*/

  useEffect(() => {
    if (props.visible) {
      setVisible(true);
    }
  }, [props]);

  return (
    <div>
      <Alert
        color={props.color}
        toggle={props.allowClose ? onDismiss : null }
        isOpen={visible}
        onClick={props.onClick}
        className={props.className}
        timeout={props.timeout ? timeout() : null}
      >
        <b>{props.value}</b>
      </Alert>
    </div>
  );
};

export default StatusMessage;
