import React, { useState } from 'react';
import './ServerError.scss';
import { Row, Container } from 'reactstrap';
import sadImg from '../../assets/images/png/404.png';
import Button from '../../components/button/Button';
import { Link } from 'react-router-dom';
import SupportEmail from '../../components/footer/SupportEmail';
import { useHistory } from "react-router-dom";

const ServerError = (props) => {
  const history = useHistory()
  //open support email modal
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleGoBack = () => {
    props.resetError()
    history.goBack()
  }

  return (
    <Container>
      <Row className=" page-not-found">
        <div className="col-md-12 col-xs-12 text-center">
          <h1 className="heading-page-not mt-3">OOPS!</h1>
          <h4>SOMETHING WENT WRONG!</h4>
          <div className="page-not-found-main text-center">
            <div className="page-not-found-circle">
              <h1>
                5<img src={sadImg} className="imoji-404" alt="" />0
              </h1>
              <div className="body-pageNotFound mb-3">
                <h2 className="mb-4">Internal Server Error</h2>
                <span>The server has been deserted for a while.</span>
                <br />
                <span>Please be patient or try again later</span>
                <Row className="justify-content-center">
                  <Button className="btn-404" title="Previous Page"  onClick={handleGoBack} />
                  <Link to="/" onClick={props.resetError}>
                    <Button className="alert-button text-white shadow-0" title="Go to home page" />
                  </Link>
                </Row>
              </div>
              <p onClick={toggleModal} className="contact_support_link openPopup">
                <u>Feel free to Contact us</u>
              </p>
            </div>
          </div>
        </div>
      </Row>
      <SupportEmail toggleModal={toggleModal} isModalOpen={showModal} />
    </Container>
  );
};
export default ServerError;
