import React, { useState, useContext, useEffect } from 'react';
import './Autopay.scss';
import checkinAccountImg from '../../assets/images/svgs/check.svg';
import { Modal, ModalBody } from 'reactstrap';
import Button from '../../components/button/Button';
import { useQuery } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import LoanDetailContext from '../../context/loanDetailContext';
import { Form } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import query from '../../query';
import { Link, useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import messages from '../../config/constant/messages';

const Autopay = props => {
  const history = useHistory()
  //open popup modal component functions
  const [recordToDelete, updateRecordToDelete] = useState('');

  const [paymentMethodRecord, updatePaymentMethodRecord] = useState([]);

  const [recordToAutopay, updateRecordToAutopay] = useState('');

  const loanDetail = useContext(LoanDetailContext);

  const loanId = loanDetail.loan_id;

  const [showModal, setShowModal] = useState(false);

  const [resultStatus, setResultStatus] = useState(false);

  const [autoPayInfo, setAutoPayInfo] = useState({});

  const [showDeleteModal, setDeleteModal] = useState(false);

  const deleteOn = payment_method_record => {
    updateRecordToDelete(payment_method_record);
    setDeleteModal(!showDeleteModal);
  };

  const { loading, data, refetch } = useQuery(query.getPaymentMethods, {
    variables: { loanId },
    fetchPolicy: 'network-only'
  });

  const toggleOn = payment_method_id => {
    updateRecordToAutopay(payment_method_id);
    setShowModal(!showModal);
  };

  useEffect(() => {
    const checkForPaymentSuccessParam = () => {
      const { state: historyState } = history.location
      if(historyState && historyState.paymentMethodAddedSuccess) {
        historyState.paymentMethodAddedSuccess = false
        setResultStatus({
          message: messages.success,
          value: messages.success,
          allowClose: false,
          timeout: false,
        })
      }
    }

    if (data) {
      updatePaymentMethodRecord(data.paymentMethods);
      let autoPayInfoArray = data.paymentMethods.filter(paymentMethod => {
        return paymentMethod.autopay === true;
      });
      autoPayInfoArray.length !== 0 && setAutoPayInfo(autoPayInfoArray[0]);
    };
    checkForPaymentSuccessParam();
  }, [data, recordToAutopay, history.location]);

  if (loading) {
    return <Loader />;
  }

  // if (error) {
  //   let message = error.networkError.result.error.message;
  //   auth.login();
  //   errormessage.error(message);
  //   history.push('/');
  // }

  const handleSubmit = async (e, autopaySetting) => {
    e.preventDefault();
    try {
      const { data: mutationData } = await autopaySetting({
        variables: {
          loanId: parseInt(loanId),
          paymentMethodId: parseInt(recordToAutopay),
        },
      });
      if (mutationData) {
        refetch();
        setResultStatus({
          message: messages.autopay.updateSuccess,
          color: 'success',
          allowClose: false,
          timeout: true,
        });
        setShowModal(!showModal);
      }
    } catch (err) {
      setResultStatus({
        message: err.graphQLErrors.map(e => e.message),
        color: 'danger',
        allowClose: true,
        timeout: false,
      });
      setShowModal(!showModal);
    }
  };

  const handleDelete = async (e, deletePaymentMethod) => {
    e.preventDefault();
    try {
      const { data: mutationData } = await deletePaymentMethod({
        variables: {
          loanId: parseInt(loanId),
          paymentMethodId: parseInt(recordToDelete.id),
        },
      });
      if (mutationData) {
        refetch();
        setResultStatus({
          message: messages.autopay.deleteSuccess,
          color: 'success',
          allowClose: false,
          timeout: true,
        });
        setDeleteModal(!showDeleteModal);
      }
    } catch (err) {
      setResultStatus({
        message: err.graphQLErrors.map(e => e.message),
        color: 'danger',
        allowClose: true,
        timeout: false,
      });
      setDeleteModal(!showDeleteModal);
    }
  };

  const noAutopay = Object.keys(autoPayInfo).length === 0;

  return (
    <div>
      <h2 className="heading mt-3">
        <b>Autopay Method</b>
      </h2>
      {resultStatus && (
        <StatusMessage
          color={resultStatus.color}
          value={resultStatus.message}
          allowClose={resultStatus.allowClose}
          timeout={resultStatus.timeout}
          visible={true}
          className="mt-3"
        />
      )}
      <div className="d-flex flex-wrap">
        {noAutopay ? (
          <b>Autopay is not activated</b>
        ) : (
          <div className="shadow-custom autopay-method-card">
            <span className="autopay-card-heading">
              <b>
                {autoPayInfo.bankName || autoPayInfo.cardType || 'Bank Account'} ending in …
                {autoPayInfo.lastFour}
              </b>
            </span>
            <br />
            <img src={checkinAccountImg} name="" alt="Credit/Debit Card" className="chase-card" />
            <div className="d-flex autopay-date-price">
              <div className="autopay-date">
                <span>
                  <b>Next Payment</b>
                </span>
                <br />
                <span>{data.loans[0].nextPaymentDate}</span>
              </div>
              <div className="autopay-price">
                <span>
                  <b>Amount</b>
                </span>
                <br />
                <span>{data.loans[0].monthlyPayment}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="autopay-method-lists">
        <h3>
          <b>Payment methods</b>
        </h3>
        <Link to="/PaymentMethods/AddingPaymentMethod">
          <u className="text-theme">
            <b>
              <span className="fa fa-plus"></span>
              {noAutopay ? <i> Add new autopay method</i> : <i> Add new payment method</i>}
            </b>
          </u>
        </Link>

        {data && (
          <Table className="borders">
            <thead>
              <tr>
                <th>
                  <b>Account Name</b>
                </th>
                <th>
                  <b>Type</b>
                </th>
                <th className="tcolumn">
                  <b>Autopay</b>
                </th>
                <th className="tcolumn"></th>
              </tr>
            </thead>
            <tbody>
                {paymentMethodRecord.map(paymentMethod => {
                  return (
                    <tr key={paymentMethod.id}>
                      <td>
                        {paymentMethod.bankName}…{paymentMethod.lastFour}
                      </td>
                      <td>{paymentMethod.cardType || 'Bank Account'}</td>
                      <td>
                        <label className="switch mb-0" id="auto_pay">
                          <input
                            type="checkbox"
                            checked={paymentMethod.autopay}
                            disabled={paymentMethod.autopay}
                            onChange={() => toggleOn(paymentMethod.id)}
                          />
                          <span className="slider round"></span>
                        </label>
                        {/* <TooltipBubble
                          placement="top"
                          value="Autopay Switch Button"
                          target="auto_pay"
                        /> */}
                      </td>
                      <td className="card-delete-icon text-center">
                        <i
                          className="fa fa-trash fa-2x"
                          id="delete_icon"
                          onClick={() => deleteOn(paymentMethod)}
                        ></i>
                        {/* <TooltipBubble
                          placement="top"
                          value="Delete Payment Method"
                          target="delete_icon"
                        /> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
      </div>
      {/* //on/off toggle model */}
      <Modal isOpen={showModal} className="modal-s">
        <ModalBody className="mt-3 ml-5 mr-5 pb-2">
          <h4>
            <b>Would you like to make this your new primary payment method?</b>
          </h4>
        </ModalBody>
        <Mutation mutation={query.autoPaySetting}>
          {(autopaySetting, { data, loading, error }) => (
            <div className="d-flex justify-content-center footer-model align-items-center">
              <span className="cancel-link mr-4" onClick={toggleOn}>
                Cancel
              </span>
              {loading && <Loader />}
              <Form onSubmit={e => handleSubmit(e, autopaySetting)}>
                <Button
                  type="submit"
                  className="alert-button m-0 text-white"
                  title="Confirm"
                  hvrtitle="Confirm"
                />
              </Form>
            </div>
          )}
        </Mutation>
      </Modal>
      {/* //Delete model */}
      <Modal isOpen={showDeleteModal} className="modal-s">
        <ModalBody className="mt-3 ml-5 mr-5 pb-2">
          <h4>
            <b>Are you sure you would like to delete this payment method?</b>
          </h4>
          <div className="text-center">
            <i className="delete-card-name">
              <b>
                {recordToDelete.cardType || recordToDelete.bankName || 'Bank Account'} …
                {recordToDelete.lastFour}
              </b>
            </i>
          </div>
        </ModalBody>
        <Mutation mutation={query.deletePaymentMethod}>
          {(deletePaymentMethod, { data, loading, error }) => (
            <div className="d-flex justify-content-center footer-model align-items-center">
              <span className="cancel-link mr-4" onClick={deleteOn}>
                Cancel
              </span>
              {loading && <Loader />}
              <Form onSubmit={e => handleDelete(e, deletePaymentMethod)}>
                <Button
                  type="submit"
                  title="Delete"
                  hvrtitle="Delete"
                  className="alert-button m-0 text-white"
                />
              </Form>
            </div>
          )}
        </Mutation>
      </Modal>
    </div>
  );
};

export default Autopay;
