import React from 'react';
import { Container } from 'reactstrap';

const TermsAndConditions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="align-element-top ">
        <h1>Conditions of Use</h1>
        <div>
          <p>
            Welcome to Line5.com. Line 5, LLC and/or its affiliates ("Line 5") provides website
            features and other products and services to you when you visit or use Line5.com, use
            Line 5’s services, or use software provided by Line 5 in connection with any of the
            foregoing (collectively, "Line 5 Services"). Line 5 provides the Line 5 Services subject
            to the following conditions.
          </p>
          <p>
            By viewing this website or otherwise using Line 5 Services, you agree to these
            conditions.
          </p>
          <p>
            When you use Line 5 Services you remain subject to the terms and conditions set forth in
            the Line 5 Dealer Agreement ("Dealer Agreement"). If these Conditions of Use are
            inconsistent with the Dealer Agreement, the Dealer Agreement will control.
          </p>
          <p>
            LINE 5 reserves the right to make changes to its website, policies, Services, and these
            Conditions of Use at any time without notice.
          </p>
        </div>
        <div>
          <h2>FINANCING OF PRODUCTS BY LINE 5</h2>
          <p>
            All orders for the financing of any item through LINE 5, must be done through the
            completion of a Line 5 approved Installment Contract and only after you have executed a
            Dealer Agreement. All Installment Contracts shall be subject to LINE 5’s approval and
            acceptance. The Line5.com website and Line 5 Services are for purposes of information
            only, and your use of the Line 5 Services is not intended by Line 5 to create, and such
            use shall not create, any obligation on the part of Line 5 to approve, accept, or
            otherwise finance any item.
          </p>
          <h2>COPYRIGHT</h2>
          <p>
            All content included in or made available through any Line 5 Service, such as text,
            graphics, logos, button icons, images, audio clips, digital downloads, and data
            compilations is the property of Line 5 and protected by United States and international
            copyright laws. The compilation of all content included in or made available through any
            Line 5 Service is the exclusive property of Line 5 and protected by U.S. and
            international copyright laws.
          </p>
          <h2>TRADEMARKS</h2>
          <p>
            Graphics, logos, page headers, button icons, scripts, and service names included in or
            made available through any Line 5 Service are trademarks or trade dress of Line 5 in the
            U.S. and other countries. Line 5's trademarks and trade dress may not be used in
            connection with any product or service that is not Line 5's, in any manner that is
            likely to cause confusion among customers, or in any manner that disparages or
            discredits Line 5. All other trademarks not owned by Line 5 that appear in any Line 5
            Service are the property of their respective owners, who may or may not be affiliated
            with, connected to, or sponsored by Line 5.
          </p>
          <h2>LICENSE AND ACCESS</h2>
          <p>
            Subject to your compliance with these Conditions of Use and your execution of a Dealer
            Agreement, Line 5 grants you a limited, non-exclusive, non-transferable,
            non-sublicensable license to access and make use of the Line 5 Services. This license
            does not include any derivative use of any Line 5 Service or its contents; any
            downloading or copying of account information for the benefit of another merchant; or
            any use of data mining, robots, or similar data gathering and extraction tools. All
            rights not expressly granted to you in these Conditions of Use or Dealer Agreement are
            reserved and retained by Line 5. No Line 5 Service, nor any part of any Line 5 Service,
            may be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for
            any commercial purpose without express written consent of Line 5. You may not frame or
            utilize framing techniques to enclose any trademark, logo, or other proprietary
            information (including images, text, page layout, or form) of Line 5 without express
            written consent. You may not misuse the Line 5 Services. You may use the Line 5 Services
            only as permitted by law. The licenses granted by Line 5 terminate if you do not comply
            with these Conditions of Use or any portion of the Dealer Agreement.
          </p>
          <h2>YOUR ACCOUNT</h2>
          <p>
            If you use any Line 5 Service, you are responsible for maintaining the confidentiality
            of your account and password and for restricting access to your computer, and you agree
            to accept responsibility for all activities that occur under your account or password.
            Line 5 reserves the right to refuse service, terminate accounts, or remove or edit
            content in its sole discretion.
          </p>
          <h2>LINE 5 SOFTWARE TERMS</h2>
          <p>
            You may use Line 5 software solely for purposes of enabling you to use the Line 5
            Services as provided by Line 5, and as permitted by these Conditions of Use and the
            Dealer Agreement. You may not incorporate any portion of the Line 5 software into your
            own programs or compile any portion of it in combination with your own programs,
            transfer it for use with another service, or sell, rent, lease, lend, loan, distribute
            or sub-license the Line 5 software. You may not use the Line 5 software for any illegal
            purpose. We may cease providing any Line 5 software and we may terminate your right to
            use any Line 5 software at any time. Your rights to use the Line 5 software will
            automatically terminate without notice from us if you fail to comply with any of these
            Software Terms, the Conditions of Use or the Dealer Agreement. All software used in any
            Line 5 Service is the property of Line 5 and protected by United States and
            international copyright laws.
          </p>
          <p>
            You may not, and you will not encourage, assist or authorize any other person to copy,
            modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the Line 5
            software, whether in whole or in part, or create any derivative works from or of the
            Line 5 software.
          </p>
          <p>
            You may not, and you will not encourage, assist or authorize any other person to copy,
            modify, reverse engineer, decompile or disassemble, or otherwise tamper with, the Line 5
            software, whether in whole or in part, or create any derivative works from or of the
            Line 5 software.
          </p>
          <p>
            In order to keep the Line 5 software up-to-date, we may offer updates at any time and
            without notice to you.
          </p>
          <h2>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
          <p>
            LINE 5 MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
            OPERATION OF THE LINE 5 SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS
            (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
            THROUGH THE LINE 5 SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE
            THAT YOUR USE OF THE LINE 5 SERVICES IS AT YOUR SOLE RISK.
          </p>
          <p>
            TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, LINE 5 DISCLAIMS ALL WARRANTIES,
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
            AND FITNESS FOR A PARTICULAR PURPOSE. LINE 5 DOES NOT WARRANT THAT THE LINE 5 SERVICES,
            INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
            INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE LINE 5 SERVICES, LINE 5'S
            SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM LINE 5 ARE FREE OF VIRUSES OR OTHER
            HARMFUL COMPONENTS. LINE 5 WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM
            THE USE OF ANY LINE 5 SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS
            (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
            THROUGH ANY LINE 5 SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
            PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
          </p>
          <h2>GOVERNING LAW</h2>
          <p>
            By using any Line 5 Service, you agree that the laws of the State of Florida, United
            States of America, will govern these Conditions of Use and any dispute of any sort that
            might arise between you and Line 5. You hereby irrevocably and unconditionally agree
            that any legal action or proceeding against Line 5 with respect to any Line 5 Service,
            these Conditions of Use, and any transaction contemplated hereby shall be brought in the
            State or Federal courts within the State of Florida, U.S.A. Any dispute, claim or
            controversy arising out of, as a result of, based upon, related to or in connection with
            any Line 5 Service, whether in contract, tort, statute or otherwise, that cannot be
            resolved by good faith negotiations among the parties shall be determined by final and
            binding arbitration as set forth in the Line 5 Dealer Agreement.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default TermsAndConditions;
