import {ApolloClient, ApolloLink, InMemoryCache, HttpLink, from} from '@apollo/client';
import {RetryLink} from "@apollo/client/link/retry";
import {onError} from "@apollo/client/link/error";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import history from '../../routes/history'
import {AuthContextRef} from "../../context/AuthContextProvider";

const env = runtimeEnv();
const url = process.env.NODE_ENV === 'production' ? env.REACT_APP_STAGING_URL : env.REACT_APP_DEVELOP_URL

const httpLink = new HttpLink({
  uri: url,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error
  }
});

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({message, locations, path}) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (networkError && networkError.statusCode && networkError.statusCode === 401) {
    localStorage.setItem("sessionExpired", 'true');
    localStorage.removeItem('loan_id');
    localStorage.removeItem("user_auth_data");
    AuthContextRef.current.logout();
    history.push("/login");
  }
});

const authLink = new ApolloLink((operation, forward, onW) => {
  const token = localStorage.getItem('user_auth_data');

  operation.setContext(({headers}) => ({
    headers: {
      authorization: `Token ${token}`,
      accept: 'application/json',
      ...headers
    }
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([authLink, errorLink, retryLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
