import React from "react";
import Button from '../../components/button/Button';

const PaymentInfoBox = ({paymentInfoValues, cancelNextScheduledPayment, showMakePaymentBtn, handleNewPayment }) => {
  const renderPaymentInfo = () => {
    let list ='';

    let total = paymentInfoValues['amount'] + parseFloat(paymentInfoValues['convenienceFee']);

    Object.keys(paymentInfoValues).map(function(key) {
      if (paymentInfoValues[key] && key !== 'date' && key !== 'paymentMethod') {
        let amount = parseFloat(paymentInfoValues[key]);


        list += `<div class='list-group-item'>
                <div class='row'>
                    <div class='col-5 text-capitalize'>${key.replace(/([A-Z])/g, " $1")}</div>
                    <div class='col'>$${amount.toFixed(2)}</div>
                  </div>
                </div>`
      } else {
        list += `<div class='list-group-item'>
                <div class='row'>
                    <div class='col-5 text-capitalize'>${key.replace(/([A-Z])/g, " $1")}</div>
                    <div class='col'>${paymentInfoValues[key]}</div>
                  </div>
                </div>`
      }
      return list;
    });

    list += `<hr style='display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; width: 80%'/>
                <div class='list-group-item'>
                <div class='row'>
                    <div class='col-5 text-capitalize'>Total</div>
                    <div class='col'>$${total}</div>
                  </div>
                </div>`

    return (<div className={'list-group'} dangerouslySetInnerHTML={{ __html: list }}></div>);
  }

  return <div className={'mt-2'}>
    <h3>Payment Info</h3>
      {renderPaymentInfo()}
    {cancelNextScheduledPayment && <div>Your next scheduled payment will be cancelled</div>}
    {showMakePaymentBtn && <Button title={'Make another Payment'} onClick={handleNewPayment}/>}
  </div>
}

export default PaymentInfoBox;