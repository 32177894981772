import React from 'react'
import ServerError from '../pages/serverError/ServerError';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    if (window.rollbar) {
      window.Rollbar.error(error);
    }
  }

  resetError = () => {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <ServerError resetError={this.resetError} />
    }
    return this.props.children;
  }
}

export default ErrorBoundary
