import React, { useState } from 'react';
import { Form } from 'reactstrap';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import { Mutation } from '@apollo/react-components';
import Loader from '../../components/loader/Loader';
import messages from '../../config/constant/messages';
import query from '../../query';
import './login.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import StatusMessage from '../../components/statusMessage/StatusMessage';

const ForgotPassword = ({ toggleModal, handleSuccess, isModalOpen }) => {
  const [values, setValues] = useState({
    email: '',
    formErrors: { email: '' },
  });

  const [forgotPassError, setForgotPassError] = useState(false);

  const handleToggle = () => {
    toggleModal();
    setValues({
      email: '',
      formErrors: {
        email: '',
      },
    });
    setForgotPassError(null);
  };

  const validate = e => {
    e.preventDefault();
    let status = true;

    if (values.email === '' || !values.email) {
      status = 'false';
      setForgotPassError({ message: messages.forgotPasswordMessages.emptyError, color: 'danger' });
    }
    return status;
  };

  const handleEmailChange = e => {
    e.preventDefault();
    setForgotPassError(null);
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    let formErrors = values.formErrors;
    switch (name) {
      case 'email':
        formErrors.email = value.length < 1 ? 'Required' : '';
        break;
      default:
        break;
    }
  };

  const setInspecletInfo = () => {
    if (window.__insp) {
      window.__insp.push(['tagSession', { email: values.email }]);
    }
  }

  const handleSubmit = async (e, submitForm) => {
    e.preventDefault();
    if (validate(e) === true) {
      setInspecletInfo()
      try {
        const { data: mutationData } = await submitForm({
          variables: {
            email: values.email,
          },
        });

        if (mutationData) {
          e.preventDefault();
          sendSuccess(e, values);
        }
      } catch (err) {
        setForgotPassError({ message: messages.loginErrors.default, color: 'danger' });
      }
    }
  };

  const sendSuccess = (e, values) => {
    e.preventDefault();
    handleSuccess(values);
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} className="modal-s">
        <Mutation mutation={query.forgotPassword} errorPolicy="all">
          {(submitForm, { loading }) => (
            <React.Fragment>
              <Form onSubmit={e => handleSubmit(e, submitForm)}>
                <h4 className="text-theme pt-4">
                  <b>Forgot Password?</b>
                </h4>
                {loading && <Loader />}
                {forgotPassError && (
                  <StatusMessage
                    color={forgotPassError.color}
                    value={forgotPassError.message}
                    allowClose={false}
                    className="modal-s-error"
                  />
                )}
                <ModalBody className="pt-4 px-5 pb-2 contactForm">
                  <InputText
                    type="email"
                    name="email"
                    id="emailAddress"
                    placeholder="Email"
                    value={values.email || ''}
                    onChange={handleEmailChange}
                    rows={1}
                    errorsMessage={values.formErrors ? values.formErrors.email : ''}
                  />
                </ModalBody>
                <ModalFooter className="justify-content align-items-center mt-4 no-border">
                  <Button
                    type="button"
                    title="Close"
                    className="openPopup btn-custom-primary btn"
                    onClick={handleToggle}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    title="Send Instructions"
                    hvrtitle="Send Button"
                    className="alert-button m-0 text-white"
                  ></Button>
                </ModalFooter>
              </Form>
            </React.Fragment>
          )}
        </Mutation>
      </Modal>
    </div>
  );
};
export default ForgotPassword;
