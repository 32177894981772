import React from 'react';
import LogoImg from '../../assets/images/logo/logo-larger.png';
import { useHistory } from 'react-router-dom';

const Logo = () => {
  const history = useHistory();

  return (
    <div onClick={() => history.push('/')}>
      <img src={LogoImg} className="img-fluid logo m-auto" alt="" />
    </div>
  );
};

export default Logo;
