import React from 'react';
import Logo from '../../components/logo/Logo';
import { Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import InputSelect from '../../components/inputSelectBox/InputSelect';
import checkImg from '../../assets/images/png/check@2x-1.png';
const SelectPaymentType = () => {
  return (
    <Container>
      <form className="align-element-center">
        <div className="text-center py-1 my-lg-5 col-lg-12 col-md-12 col-12">
          <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-12 ">
            <Logo />
            <div className="mt-5">
              <h3 className="mb-0 heading">
                <b>Select a payment type.</b>
              </h3>
              <span className="below-headint-text">
                Line5 requires automatic payments. To complete your account activation, add a
                payment method.
              </span>
            </div>
          </div>
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-12 mt-5">
            <img src={checkImg} name="" className="img-fluid" alt="Check" />
            <Row>
              <div className="col-lg-6 col-md-6 col-12">
                <InputText
                  name="chase_bank"
                  id="chase_banck"
                  placeholder="Chase Banck"
                  className="text-font-size"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 text-left">
                <InputSelect
                  type="select"
                  name="select"
                  id="exampleSelect"
                  className="text-font-size select-input"
                ></InputSelect>
                <i>
                  <span className="forgot-pass">
                    Line 5 does not accept Savings Account for ACH Payments.
                  </span>
                </i>
              </div>
            </Row>
            <Row>
              <div className="col-lg-6 col-md-6 col-12">
                <InputText name="" id="" placeholder="000000000" className="text-font-size" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <InputText name="" id="" placeholder="000000000" className="text-font-size" />
              </div>
            </Row>
            <Row>
              <div className="col-lg-6 col-md-6 col-12">
                <InputText name="" id="" placeholder="000000000" className="text-font-size" />
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <InputText name="" id="" placeholder="000000000" className="text-font-size" />
              </div>
            </Row>
            <Row className="justify-content-center align-items-center mt-5">
              <div className="mr-4">
                <Link to="/SelectPaymentType" title="Back">
                  Back
                </Link>
              </div>
              <div className="login-btn ml-2">
                <Link to="/MyAccount">
                  <Button title="Next" hvrtitle="Next Button"></Button>
                </Link>
              </div>
            </Row>
          </div>
        </div>
      </form>
    </Container>
  );
};

export default SelectPaymentType;
