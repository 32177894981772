import React from 'react';
import './Button.scss';

const Button = ({ title, hvrtitle, type, className, onClick }) => {
  return (
    <div className="ml-2">
      <button
        type={type}
        className={'btn-custom-primary btn-custom-sm ' + className}
        title={hvrtitle}
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  );
};

export default Button;
