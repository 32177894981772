import React, { useContext } from 'react';
import { Row } from 'reactstrap';
import './SelectAccount.scss';
import { Link } from 'react-router-dom';
import warningImg from '../../assets/images/png/icon-warning.png';
import LoanDetailContext from '../../context/loanDetailContext';

const SelectAccount = ({data}) => {
  const userName = localStorage.getItem('user_auth_name');
  const loanDetail = useContext(LoanDetailContext);
  const setLoanId = loan_id => {
    localStorage.setItem('loan_id', loan_id);
    loanDetail.loan_detail();
  };

  const pastDueAmountToNumber = amount => {
    return Number(amount.replace(/\$|,/g, ''))
  }

  return (
    <div className="align-element-top py-1 py-lg-5 py-md-5 text-center mb-5 mt-3 mt-lg-0">
        <div className="col-lg-12 col-md-12 col-12 px-md-3 px-lg-3 p-0">
          <div className="mb-0 mb-lg-5 mb-md-4 mt-0 mt-lg-5">
            <h2 className="greeting-heading">
              <b className="text-theme heading">Hello</b> {userName}
            </h2>
          </div>
          <div className="col-12 col-lg-12 col-md-12 pt-3 pt-md-5 pt-lg-4 p-lg-1 p-0">
            <h2 className="heading">
              <b>Select Account</b>
            </h2>
            <Row className="justify-content-center text-left">
            {data.loans.map(loan => (
              <Link
                to="/AccountSummary"
                key={loan.id}
                onClick={() => setLoanId(loan.id)}
                className="my-accounts-container mr-2"
              >
                <div className="select-account text-center">
                  <h4 className="text-center header">Account #{loan.id}</h4>
                  
                  {pastDueAmountToNumber(loan.pastDueAmount) !== 0 && (
                    <div className="due-amount-warning mt-3 text-center">
                      <div>
                        <img src={warningImg} alt="warning" className="warning-icon" />
                      </div>
                      <b className="text-red">You have a past due amount of {loan.pastDueAmount}</b>
                    </div>
                  )}
                  { loan.vehicle ? (
                    <div className="mt-5 text-left">
                      <span>Vehicle</span>
                      <h5 className="text-theme mb-0 mt-1">
                        <b>{loan.vehicle.year + '/' + loan.vehicle.make + '/' + loan.vehicle.model}</b>
                      </h5>
                      <span>({loan.vehicle.vin})</span>
                    </div>
                    ) : (
                    <div className="mt-5 text-left">
                      <span>House</span>
                      <h5 className="text-theme mb-0 mt-1">
                        <b>{loan.house.coveredAddress}</b>
                      </h5>
                    </div>
                    )}
                  <div className="mt-5">
                    <u className="grey-text"> View Account</u>
                  </div>
                </div>
              </Link>
            ))}
          </Row>
          </div>
        </div>
      </div>
  );
};

export default SelectAccount;
