import { Field } from "formik";
import React from "react";

const ToggleSwitch = ({ name, isOn, handleToggle }) => {
  return (
    <div className={'d-flex'}>
      <label className="switch">
        <Field type="checkbox" name={name} checked={isOn} value={isOn} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
      <span className="pl-2">
        <p className={'text-capitalize'}>{name}</p>
      </span>
    </div>
  )
}

export default ToggleSwitch;