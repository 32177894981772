import React from 'react';
import './noDataFound.scss';
import { Table, Tbody, Tr, Td } from 'react-super-responsive-table';
const noDataFound = () => {
  return (
    <React.Fragment>
      <Table>
        <Tbody className="panel">
          <Tr className="table-accordion">
            <Td colSpan="100%">No Data Found </Td>
          </Tr>
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export default noDataFound;
