import React, {useContext, useEffect} from 'react';
import './MyCoverage.scss';
import { Row } from 'reactstrap';
import LoanDetailContext from '../../context/loanDetailContext';
import { useLazyQuery } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import query from '../../query';

const MyCoverage = () => {

  const LoanDetail = useContext(LoanDetailContext);

  const loanId = LoanDetail.loan_id;

  const [getProtections, { loading, data, error }] = useLazyQuery(query.getProtections, {
    variables: { loanId: loanId },
  });

  useEffect(() => {
    getProtections();
  }, [])

  // if (error) {
  //   localStorage.removeItem('user_auth_data');
  //   let message = error.networkError.result.error.message;
  //   auth.login();
  //   errormessage.error(message);
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/',
  //       }}
  //     />
  //   );
  // }

  if (loading) return <Loader />;

  return (
    <div className="col-md-12 col-lg-12 col-12 py-2 mb-5 mt-mbl-xs">
      <h2 className="heading">
        <b>My Coverage</b>
      </h2>
      {data && (
        <div>
            {data.loans[0].vehicle ? (
              <b className="msg-text">
                {data.loans[0].vehicle.make} {data.loans[0].vehicle.model}
              </b>
                ) : (
                <b className="msg-text">
                  {data.loans[0].house.coveredAddress}
                </b>
            )}
        </div>
      )}
      <div>Contact your provider(s) below to make a claim</div>
      <Row>
        {data && data.loanProtections.map(loanProtection => (
          <div className="d-flex flex-wrap my-coverage tab-contents" key={loanProtection.id}>
            <div className="bg-white my-coverage-block custom-border shadow-custom">
              <div className="type-block">
                <h6 className="text-theme product-type-text">
                  <b>{loanProtection.category}</b>
                </h6>
                <div className="pl-0 coverage-details">
                  <b>Protection Name</b>
                  <div>{loanProtection.name}</div>
                  <b>Contract #</b>
                  <div>{loanProtection.contract}</div>
                </div>
              </div>
              <div className="provider-name mt-0 mt-lg-4">
                <h5 className="below-headint-text mb-0">
                  <b>{loanProtection.providerName}</b>
                </h5>
                <div>
                  <a href={'http://' + loanProtection.providerWebsite}>
                    {loanProtection.providerWebsite}
                  </a>
                </div>
                {loanProtection.providerClaimsPhoneNumber ? (
                  <div>
                    <i>
                      <b>Claims:</b>
                    </i>
                    <a href={'tel:' + loanProtection.providerClaimsPhoneNumber}>
                      {loanProtection.providerClaimsPhoneNumber}
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {loanProtection.providerRoadsideAssistanceNumber ? (
                  <div>
                    <i>
                      <b>Roadside Assistance:</b>
                    </i>
                    <a href={'tel:' + loanProtection.providerRoadsideAssistanceNumber}>
                      {loanProtection.providerRoadsideAssistanceNumber}
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default MyCoverage;
