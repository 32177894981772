import React from 'react';
import loaderImg from '../../assets/images/gif/loader.gif';
import './Loader.scss';

const Loader = () => {
  return (
    <div>
      <div className="loader-bg"></div>
      <img src={loaderImg} alt="Loader" className="loader-img" />
    </div>
  );
};

export default Loader;
