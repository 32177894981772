import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import { Row } from 'reactstrap';
import './StatementNotification.scss';
import  Button from '../../components/button/Button.js';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { useLazyQuery } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import LoanDetailContext from '../../context/loanDetailContext';
import NoDataFound from '../../components/noDataFound/noDataFound';
import axios from 'axios';
import query from '../../query';
import Message from '../../components/statusMessage/StatusMessage';
import messages from '../../config/constant/messages';
import moment from "moment";

const StatementNotification = () => {
  React.useEffect(() => {
    window.scrollTo(0, 500);
  }, []);

  const communicationsListWithFile = ['Welcome Letter'];
  
  const loanDetail = useContext(LoanDetailContext);

  const loanId = loanDetail.loan_id;

  const [years, setYears] = useState();

  const [currentYear, setCurrentYear] = useState();

  const [records, setRecords] = useState([]);

  const [fetchAccount, { loading, data, error }] = useLazyQuery(query.getStatements, {
    variables: { loanId: loanId },
  });

  const recordIsStatement = record => record['__typename'] == "Statement"

  const recordIsCommunication = record => {
    return record['__typename'] == "Communication" && communicationsListWithFile.includes(record['title'])
  }

  const fetchGraphqlQuery = useCallback( async () => {
    await fetchAccount();
    if (data) {
      const { statements, communications } = data;
      setRecords([...statements, ...communications]);
    }
  }, [data, fetchAccount])

  useEffect( () => {
     fetchGraphqlQuery();
  }, [data, loading, fetchGraphqlQuery]);

  useEffect(() => {
    const handleYearOptions = () => {
      let years = []
      records.length > 0 && records.map(r => {
        const year = new Date(r['createdAt']).getFullYear();
        if (years.indexOf(year) === -1) {
          years.push(year);
        }
        return '';
      })
      setYears(years);
    }
    handleYearOptions();
  }, [records])

  const filterRecords = e => {
    let currentYear = e.target.value;
    setCurrentYear(currentYear);
  }

  const getMonthAndYearStr = (date, char) => {
    const month = moment(date).format('MMMM');;
    const year = moment(date).format('YYYY');
    return `${month}${char}${year}`;
  };

  const renderTableRow = record => {
    if (recordIsStatement(record)) {
      return (
        <Tr key={record.id}>
          <Td>{`Monthly Statement for ${getMonthAndYearStr(record.date, ' ')}`}</Td>
          <Td>{formatDate(record['createdAt'])}</Td>
          <Td className={'d-flex justify-content-center'}>
            <a href={record['downloadUrl']} className={'btn btn-link'}>Download</a>
          </Td>
        </Tr>
      )
    } else if (recordIsCommunication(record)) {
      return (
        <Tr key={record.id}>
          <Td>{`${record.title}`}</Td>
          <Td>{formatDate(record['createdAt'])}</Td>
          <Td className={'d-flex justify-content-center'}>
            <a target={'_blank'} className={'btn btn-link'}>Download</a>
          </Td>
        </Tr>
      )
    } else {
      return (
        <Tr key={record.id}>
          <Td>{`${record.title}`}</Td>
          <Td>{formatDate(record['createdAt'])}</Td>
          <Td></Td>
        </Tr>
      )
    }
  }

  const formatDate = (dateStr) => moment(dateStr).format('MM/DD/YYYY');

  const renderAllRecords = () => records.sort((a, b) => {
    const date1 = new Date(a['createdAt'])
    const date2 = new Date(b['createdAt'])
    return date2 - date1
  }).map(record => renderTableRow(record));

  const renderFilteredRecords = () => records
    .filter(record => new Date(record['createdAt']).getFullYear() === parseInt(currentYear))
    .sort((a, b) => {
      const date1 = new Date(a['createdAt'])
      const date2 = new Date(b['createdAt'])
      return date2 - date1
    })
    .map(record => renderTableRow(record));

  return (
    <Row className="centered">
      {loading && <Loader />}
      {error && <Message color="danger" value={error.graphQLErrors.length
            ? error.graphQLErrors.map(e => e.message)
            : messages.default} />}
      {records ? (
        <div className="col-md-12 col-lg-12 col-12 my-4">
          <div className=" align-items-center">
            <h3>
              <b>Statements & Notifications </b>
            </h3>
            {records && (
              <div className="col-md-12 mt-3 mt-lg-5">
                <Row>
                  <div>
                    <label htmlFor="selectYear">Select Year </label>
                    <select
                      id="selectYear"
                      className={'filter-statements select-year'}
                      onChange={filterRecords}
                    >
                      <option value={''}>All</option>
                      {years && years.map(year => <option key={year} value={year}>{year}</option>)}
                    </select>
                  </div>
                </Row>
              </div>
            )}
          </div>
            <Table className="statement-table">
              <Thead>
                <Tr>
                  <Th>Document Type</Th>
                  <Th>Date</Th>
                  <Th>View/Download</Th>
                </Tr>
              </Thead>
              <Tbody className="panel">{currentYear ? renderFilteredRecords() : renderAllRecords()}
              </Tbody>
            </Table>
        </div>
      ) : <NoDataFound />}
    </Row>
  );
};

export default StatementNotification;
