import React from 'react';
import Logo from '../../components/logo/Logo';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';


const SelectPaymentType = () => {
  return (
    <Container>
      <div className="align-element-center">
        <div className="text-center py-1 my-lg-5 col-lg-12 col-md-12 col-12">
          <div className="col-md-6 offset-md-6 col-lg-4 offset-lg-4 col-12 text-center ">
            <Logo />
            <div className="mt-5">
              <h3 className="mb-0 heading">
                <b>Select a payment type.</b>
              </h3>
              <span className="below-headint-text">
                Line5 requires automatic payments. To complete your account activation, add a
                payment method.
              </span>
            </div>
          </div>
          <div className=" text-center">
            <div className=" mt-5 pt-5">
              <div className=" mr-0 mr-lg-5">
                <Link to="/AddPaymentMethod" title="Back" className="cancel-link">
                  Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SelectPaymentType;
