import React, {useState, useEffect} from 'react';
import './App.scss';
import {ApolloProvider} from '@apollo/client';
import Routes from './routes/route';
import ErrorContext from './context/errorContext';
import LoanDetailContext from './context/loanDetailContext';
import client from './config/apolloConfig/apolloConfig';
import loadScripts from "./utils/load_scripts";
import AuthContextProvider from "./context/AuthContextProvider";
import PaymentContextProvider from "./context/PaymentContextProvider";

const App = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loanId, setLoanId] = useState('');

  const loanDetail = () => {
    const loan_id = localStorage.getItem('loan_id');
    if (loan_id) {
      setLoanId(loan_id);
    }
  };

  useEffect(() => {
    loanDetail();
    loadScripts();
  }, []);

  const error = (message = '') => {
    if (message) {
      setErrorMessage(message);
    }
  };

  return (
    <ApolloProvider client={client}>
      <AuthContextProvider>
        <PaymentContextProvider>
          <ErrorContext.Provider value={{errorMessage: errorMessage, error: error}}>
            <LoanDetailContext.Provider value={{loan_id: loanId, loan_detail: loanDetail}}>
              <Routes/>
            </LoanDetailContext.Provider>
          </ErrorContext.Provider>
        </PaymentContextProvider>
      </AuthContextProvider>
    </ApolloProvider>


  );
};
export default App;
