import React, { useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import SelectAccount from '../../components/selectAccount/SelectAccount';
import { useQuery } from '@apollo/client';
import Loader from '../../components/loader/Loader';
import ErrorContext from '../../context/errorContext';
import LoanDetailContext from '../../context/loanDetailContext';
import query from '../../query';
import { Redirect } from 'react-router-dom';
import NoLoanErrorPage from './NoLoanErrorPage';

const DefaultLandingPage = () => {
  const errorMessage = useContext(ErrorContext);

  const setErrorMessage = (message) => errorMessage.message = message;

  const loanDetail = useContext(LoanDetailContext);

  const { loading, error, data } = useQuery(query.GET_LOANS);

  useEffect(() => {
    // Method to set the load ID of the selected loan
    const updateLoanId = loan_id => {
      localStorage.setItem('loan_id', loan_id);
      loanDetail.loan_detail();
    };

    if (data) {
      if (data.loans.length !== 0) {
        updateLoanId(data.loans[0].id);
      }
    }
  },[data, loanDetail])

  const defaultPage = data => {
    if (data.loans.length > 1) {
      return <SelectAccount data={data}/>
    }else {
      return (
        <Redirect
          to={{
            pathname: '/AccountSummary',
          }}
        />
      );
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    let { graphQLErrors, networkError } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) =>
        setErrorMessage(`Error: ${message}`)
      )
    }

    if (networkError && networkError.statusCode) {
      setErrorMessage(`Network Error: ${networkError.message}`)
    }
  }

  return (
    <Container>
      {data.loans.length === 0 ? <NoLoanErrorPage/> : defaultPage(data)}
    </Container>
  );
};
export default DefaultLandingPage;
