import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import Button from '../button/Button';
import './PaymentExpire.scss';
import LoanDetailContext from '../../context/loanDetailContext';
import query from '../../query';
import monthList from '../../config/constant/monthList';
const moment = require('moment')

const PaymentExpire = (props) => {
  const [isAutoExpire, updateIsAutoExpire] = useState(false);
  const [autopayMessage, updateAutopayMessage] = useState(false);
  const [statementBlock, updateStatementBlock] = useState('');
  const [statementMessage, updateStatementMessage] = useState('');
  const [shawStatement, setShawStatement] = useState(false);
  const history = useHistory();

  const loanDetail = useContext(LoanDetailContext);

  const loanId = loanDetail.loan_id;

  const { data } = useQuery(query.notificationDetail, {
    variables: { loanId },
  });

  const check_transection_type = autopay_true_record => {
    if (autopay_true_record && autopay_true_record.transactionType === 'creditcard') {
      const current_date = new Date();
      if (autopay_true_record.expDate < current_date) {
        updateIsAutoExpire(true);
        updateAutopayMessage(
          'Your autopay payment method is expired. Please choose a different payment method.'
        );
      }
    } else {
    updateIsAutoExpire(false);
    }
  };

  //Method to retrieve the Month's name from the date string
  const getMonthName = date => {
    let finalDate = new Date(date);
    let thisMonth = monthList.months[finalDate.getMonth()];
    return thisMonth;
  };

  useEffect(() => {
    // show statement message only for 3 days after it has been created
    const showStat = (statement_records) => {
      // Get the last item
      const latestStatement = statement_records[statement_records.length - 1]

      // get the date from the last item - this is the created at date
      const cDate = latestStatement.createdAt.split(' ')[0]

      // get todays date and format it to match createdAt Date
      const today = moment().format('YYYY-MM-DD')

      // convert strings to dates
      const todayWithDate = new Date(today)
      const createdWithDate = new Date(cDate)

      // subtract createdAt - todaysDate
      const diff = getDayDiff(createdWithDate, todayWithDate)

      setShawStatement(diff <= 3);
    }

    if (data) {
      const autopay_records = data.paymentMethods;
      const statement_records = data.statements;
      var autopay_true_record = autopay_records.filter(record => record.autopay === true);
      if (autopay_true_record == '') {
        updateIsAutoExpire(true);
        updateAutopayMessage('No payment method is set as AutoPay');
      } else {
        check_transection_type(autopay_true_record[0]);
      }
      if (statement_records == '') {
        updateStatementBlock(false);
      } else {
        updateStatementBlock(true);
        const statement_date = statement_records[statement_records.length - 1].date;
        let statement_month = getMonthName(statement_date);
        let statement_year = new Date(statement_date).getFullYear();
        let message =
          'Your statement is ready for the month of ' +
          statement_month +
          ' ' +
          statement_year +
          '.';
        updateStatementMessage(message);
        showStat(statement_records);
      }
    }
  }, [data]);

  const getDayDiff = (dateOne, dateTwo) => {
    const oneDay = 24 * 60 * 60 * 1000; 
    const differenceOfDays = Math.abs((dateOne - dateTwo) / oneDay)
    const diffDays = Math.round(differenceOfDays);
  
    return diffDays
  }

  return (
    <React.Fragment>
      {data && (
        <Row className={props.className}>
          {isAutoExpire && (
            <div className="alert-container">
              <div className="alert-box shadow-custom">
                <div className="expiry-msz-div mb-3 mb-lg-5 mt-lg-4">
                  <h6 className="text-white expire-text-msg">{autopayMessage}</h6>
                </div>
                <Button
                  className="alert-button text-white mb-lg-2"
                  title="Take Action"
                  hvrtitle="Setup Autopay"
                  onClick={() => history.push('/PaymentMethods/AddingPaymentMethod')}
                />
              </div>
            </div>
          )}
          {shawStatement && statementBlock && (
            <div className="alert-container">
              <div className="alert-box shadow-custom">
                <div className="expiry-msz-div mb-3 mb-lg-5 mt-lg-4">
                  <h6 className="text-white expire-text-msg">{statementMessage}</h6>
                </div>
                <Row className="d-flex justify-content-center">
                  <Button
                    className="alert-button text-white mb-lg-2"
                    title="Take Action"
                    hvrtitle="Take Action"
                    onClick={() => history.push('/Statements')}
                  />
                </Row>
              </div>
            </div>
          )}
        </Row>
      )}
    </React.Fragment>
  );
};

export default PaymentExpire;
