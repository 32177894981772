export default {
  success: 'Record has been successfully added',
  update: 'Record has been successfully updated',
  delete: 'Record has been successfully deleted',
  required: '* Required',
  notValidAccountNumber: 'Please enter a valid account number',
  notValidRoutingNumber: 'Please enter a valid routing number',
  notValidCvv: 'Please enter a valid CVV security code',
  checkInfo: 'Please check your entered information',
  matchRoutingNumber: 'Routing number does not match the Confirm Routing number',
  matchAccountNumber: 'Account number does not match the Confirm Account number',
  routingNumberLength: 'Routing number need to be 9 digit number',
  notAcceptSavingsAccount: 'Line 5 does not accept Savings Account for ACH Payments.',
  paymentError: "We can't process this transaction",
  paymentSuccess: "Payment Applied!",
  paymentCreditCardSuccess: "Thank You! Your Payment was Successful",
  paymentCreditCardError: "Your payment was declined by the card issuing bank. Please use a different payment method, or Call Customer Service at 1-844-775-4635",
  forgotPasswordMessages: {
    forgotPassSuccess:
      'If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.',
    emptyError: 'Please enter your Email',
  },
  emailSuccess: 'Email sent successfully',
  loginErrors: {
    invalid: "Please enter your Email ID and Password",
    timeout: "Your session expired. Please sign in again to continue.",
  },
  resetPassword: {
    success: 'Password reset successfully, Redirecting now...'
  },
  default: 'An Error has occurred, please contact support for help',
  findAccount: {
    findAccountSuccess:
      'If your email address exists in our database, you will receive a set up new account link at your email address in a few minutes.',
  },
  profile: {
    required: 'All required fields must be filled in',
    updated: 'Profile updated successfully',
  },
  existedPaymentWarning: 'WARNING: View Payment History to see your Scheduled Payments',
  scheduledPaymentDelete: 'Payment was removed successfully',
  autopay: {
    updateSuccess: 'Autopay was updated successfully',
    deleteSuccess: 'Payment method was removed successfully',
  }
};
