import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();

const loadScripts = () => {
  const scripts = [
    {
      src: "/rollbar.js",
      attrs: [
        { name: "env", value: process.env.NODE_ENV },
        { name: "key", value: env.REACT_APP_ROLLBAR_TOKEN },
      ],
    },
    {
      src: "/inspeclet.js",
      attrs: [{ name: "key", value: env.REACT_APP_INSPECLET_ID }],
    },
    {
      src: "/live_chat.js",
      attrs: [{ name: "key", value: env.REACT_APP_LIVE_CHAT_ID }],
    },
  ];

  scripts.forEach((element) => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + element.src;
    element.attrs.forEach((element) => {
      script.setAttribute(element.name, element.value);
    });
    script.async = true;
    document.body.appendChild(script);
  });
};

export default loadScripts
