import React from "react";
import './ContentBox.scss';

const ContentBox = ({active, title, children, footer, onClick, style}) => {
  return (
    <div
      className={`content-box shadow-custom ${active && 'orange-border'}`} onClick={onClick} style={style}>
      <h5 className={'text-center'}>{title}</h5>
      <div className={'shadow-box-body'}>{children}</div>
      <div className={'shadow-box-footer'}>{footer}</div>
    </div>
  )
}

export default ContentBox;