import React, {useContext} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import Footer from '../components/footer/Footer';
import HeaderNavbar from '../components/header/HeaderNavbar';
import Login from '../pages/userLogin/Login';
import FindAccount from '../pages/findAccount/FindAccount';
import AccountFound from '../pages/accountFound/AccountFound';
import PasswordReset from '../pages/PasswordReset/PasswordReset';
import AccountActivated from '../pages/accountActivated/AccountActivated';
import AddPaymentMethod from '../pages/addPaymentMethod/AddPaymentMethod';
import SelectPaymentType from '../pages/selectPaymentType/SelectPaymentType';
import SelectPaymentForm from '../pages/selectPaymentForm/SelectPaymentForm';
import DefaultLandingPage from '../pages/defaultLandingPage/DefaultLandingPage';
import AccountSummary from '../pages/accountSummary/AccountSummary';
import Profile from '../pages/userProfile/Profile';
import PageNotFound from '../pages/pageNotFound/PageNotFound';
import Privacy from '../pages/privacy/privacy';
import TermsAndConditions from '../pages/termsAndConditions/termsAndConditions';
import ErrorBoundary from '../components/ErrorBoundary';
import history from './history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const Routes = () => {
  const routes = (
    <Switch>
      <PublicRoute path="/users/sign_in" component={Login}/>
      <PublicRoute path="/login" component={Login}/>

      <PrivateRoute exact path="/" component={DefaultLandingPage}/>
      <PrivateRoute path="/defaultLandingPage" component={DefaultLandingPage}/>
      <PrivateRoute exact path="/AddPaymentMethod" component={AddPaymentMethod}/>
      <PrivateRoute path="/SelectPaymentType" component={SelectPaymentType}/>
      <PrivateRoute path="/SelectPaymentForm" component={SelectPaymentForm}/>
      <PrivateRoute path="/AccountSummary" component={AccountSummary}/>
      <PrivateRoute path="/Statements" component={AccountSummary}/>
      <PrivateRoute path="/PaymentMethods" component={AccountSummary}/>
      <PrivateRoute path="/MakePayment" component={AccountSummary}/>
      <PrivateRoute path="/MyCoverage" component={AccountSummary}/>
      <PrivateRoute path="/Profile" component={Profile}/>

      <Route path="/FindAccount" component={FindAccount}/>
      <Route path="/AccountFound" component={AccountFound}/>
      <Route path="//users/password/edit" component={PasswordReset}/>
      <Route path="/users/password/edit" component={PasswordReset}/>
      <Route path="/AccountActivated" component={AccountActivated}/>
      <Route path="/Privacy" component={Privacy}/>
      <Route path="/TermsAndConditions" component={TermsAndConditions}/>
      <Route component={PageNotFound}/>
    </Switch>
  );

  return (
    <Router history={history}>
      <ErrorBoundary>
        <div id={'page-container'}>
          <HeaderNavbar/>
          <div id="content-wrap">
            <div className="content">{routes}</div>
          </div>
          <Footer/>
        </div>
      </ErrorBoundary>
    </Router>
  );
};

export default Routes;
