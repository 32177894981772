import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/* MenuItem.jsx*/
const MenuItem = props => {

  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover({ hover: !hover });
  };

  const handleClick = () => {
      props.onClick();
  };

  const styles = {
    container: {
      opacity: 0,
      padding: '0.3rem 16px',
      animation: '1s appear forwards',
      animationDelay: props.delay,
      lineHeight: '3.1',
      cursor: 'pointer',
    },
    menuItem: {
      fontSize: '1rem',
      margin: '0 0%',
      transition: 'color 0.2s ease-in-out',
      animation: '0.5s slideIn forwards',
      animationDelay: props.delay,
    },
  };

  const body = () => {
    return (
      <div
        style={styles.menuItem}
        onMouseEnter={() => {
          handleHover();
        }}
        onMouseLeave={() => {
          handleHover();
        }}
        onClick={handleClick}
        title={props.title}
        id={"Tooltip-" + props.id}
        className={"user-menu-img " + props.className}
      >
        {props.children}
      </div>
    );
  };

  return (
    <div style={styles.container} className="item">
      {typeof props.children === "object" ? (
        body()
      ) : (
        <Link to={props.url}>{body()}</Link>
      )}
    </div>
  );
};

export default MenuItem;
