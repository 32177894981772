import React, { useState } from 'react';
import { Row, Form } from 'reactstrap';
import { Mutation } from '@apollo/react-components';
import query from '../../query';
import Loader from '../../components/loader/Loader';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import './footer.scss';
import { Modal, ModalBody } from 'reactstrap';
import InputText from '../inputText/InputText';
import Button from '../button/Button';
import messages from '../../config/constant/messages';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import ReCAPTCHA from "react-google-recaptcha";

const SupportEmail = ({ toggleModal, isModalOpen }) => {
  const env = runtimeEnv();
  const [supportEmailStatus, setSupportEmailStatus] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null)

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    subject: '',
    message: '',
    formErrors: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      subject: '',
    },
  });

  const resetModal = () => {
    setSupportEmailStatus(null);
    setValues({
      firstName: '',
      lastName: '',
      emailAddress: '',
      subject: '',
      message: '',
      formErrors: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        subject: '',
      },
    });
  };

  const handleSubmit = async (e, submitForm) => {
    e.preventDefault();
    setSupportEmailStatus(null);
    if (validate(e) === true ) {
      try {
        const { data: mutationData } = await submitForm({
          variables: {
            subject: values.subject,
            fullName: values.firstName + ' ' + values.lastName,
            emailAddress: values.emailAddress,
            message: values.message,
            recaptchaToken: captchaToken
          },
        });
        if (mutationData) {
          setSupportEmailStatus({ message: messages.emailSuccess, color: 'success' });
          sendSuccess();
        }
      } catch (err) {
        setSupportEmailStatus({
          message: err.graphQLErrors.length
            ? err.graphQLErrors.map(e => e.message)
            : messages.loginErrors.default,
          color: 'danger',
        });
      }
    } else {
      setSupportEmailStatus({ message: 'Unable to submit', color: 'danger' });
    }
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    let formErrors = values.formErrors;
    switch (name) {
      case 'firstName':
        formErrors.firstName = value.length < 1 ? 'required' : '';
        break;
      case 'lastName':
        formErrors.lastName = value.length < 1 ? 'required' : '';
        break;
      case 'emailAddress':
        formErrors.emailAddress = value.length < 1 ? 'required' : '';
        break;
      case 'subject':
        formErrors.subject = value.length < 1 ? 'required' : '';
        break;
      default:
        break;
    }
  };

  const validate = e => {
    let status = true;

    if (
      values.firstName === '' ||
      values.lastName === '' ||
      values.emailAddress === '' ||
      values.subject === ''
    ) {
      status = 'false';
      setSupportEmailStatus({ message: messages.required, color: 'danger' });
    }
    return status;
  };

  const sendSuccess = () => {
    setTimeout(() => {
      toggleModal();
    }, 3000);
  };

  return (
    <Modal isOpen={isModalOpen} onClosed={resetModal} className="modal-l">
      <ModalBody className="pt-lg-4 px-lg-5 p-4 pb-2 contactForm">
        <h2 className="heading text-theme">
          <b>Contact Us</b>
        </h2>
        <Mutation mutation={query.contactUs}>
          {(submitForm, { loading }) => (
            <React.Fragment>
              <Form onSubmit={e => handleSubmit(e, submitForm)}>
                {loading && <Loader />}
                {supportEmailStatus && (
                  <StatusMessage
                    color={supportEmailStatus.color}
                    value={supportEmailStatus.message}
                    allowClose={false}
                    className="modal-s-error"
                  />
                )}
                <div className="border p-4">
                  <Row>
                    <div className="col-md-6 col-xs-12">
                      <InputText
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={handleChange}
                        className="text-font-size"
                        errorsMessage={values.formErrors ? values.formErrors.firstName : ''}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <InputText
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        className="text-font-size"
                        errorsMessage={values.formErrors ? values.formErrors.lastName : ''}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12 col-xs-12">
                      <InputText
                        type="email"
                        name="emailAddress"
                        id="emailAddress"
                        placeholder="Email"
                        value={values.emailAddress}
                        onChange={handleChange}
                        className="text-font-size"
                        errorsMessage={values.formErrors ? values.formErrors.emailAddress : ''}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12 col-xs-12">
                      <InputText
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        value={values.subject}
                        onChange={handleChange}
                        className="text-font-size"
                        errorsMessage={values.formErrors ? values.formErrors.subject : ''}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12 col-xs-12">
                      <InputText
                        type="textarea"
                        name="message"
                        id="message"
                        placeholder="Drop Your Message Here"
                        value={values.message}
                        onChange={handleChange}
                        className="text-font-size textareaHeight"
                        noValidate
                      />
                    </div>
                  </Row>
                </div>
                <Row className="justify-content align-items-center mt-4">
                  <ReCAPTCHA
                    sitekey={env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={setCaptchaToken}
                  />
                </Row>
                <Row className="justify-content align-items-center mt-4">
                  <div>
                    <Button
                      type="button"
                      title="Close"
                      className="openPopup btn-custom-primary btn"
                      onClick={toggleModal}
                    >
                      Close
                    </Button>
                  </div>
                  <div className="login-btn">
                    <Button
                      type="submit"
                      title="Send"
                      hvrtitle="Send Button"
                      className="alert-button m-0 text-white"
                    ></Button>
                  </div>
                </Row>
              </Form>
            </React.Fragment>
          )}
        </Mutation>
      </ModalBody>
    </Modal>
  );
};

export default SupportEmail;
