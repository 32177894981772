import React from 'react';
import Logo from '../../components/logo/Logo';
import { Container, Row, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import './AccountFound.scss';

const AccountFound = () => {
  return (
    <Container>
      {/* start form */}
      <Form className="m-auto py-5 align-element-center text-center">
        <div className="col-lg-4 col-12 offset-lg-4 col-md-6">
          {/* Logo component */}
          <Logo />
          <div className="mt-4">
            <h3 className="heading">
              <b>Account Found!</b>
            </h3>
            <div className="below-header-text mt-4">
              Request the link to activate your new account
            </div>
          </div>
          {/* <div className="mt-4 pass-container ">
            <h5 className="text-center">
              <b>Password Requirements</b>
            </h5>
            <label className="m-2 pl-5 password-required-tick">
              Must have at least 8 charecters
            </label>
            <br />
            <label className="m-2 pl-5 password-required">
              Must contain at least one capital letter
            </label>
            <br />
            <label className="m-2 pl-5 password-required">Must have at least one number</label>
          </div> */}
          {/* Input Components */}
          <div className="mt-1">
            <InputText
              type="email"
              name="email"
              id="email"
              placeholder="Enter email"
              className="text-font-size"
            />
            {/* <InputText
              type="password"
              name="con_password"
              id="con_password"
              placeholder="********"
              className="text-font-size"
            /> */}
          </div>
          <Row className="justify-content-between align-items-center mt-4 mt-lg-5 ml-0 mr-0">
            <div className="link-div mr-4">
              <Link to="/" title="Cancel" className="cancel-link">
                Cancel
              </Link>
            </div>
            <div className="login-btn ml-2">
              <Link to="/AccountActivated">
                <Button title="Send Activation"></Button>
              </Link>
            </div>
          </Row>
        </div>
      </Form>
    </Container>
  );
};
export default AccountFound;
