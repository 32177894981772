import React, { useContext, useState } from 'react';
import LoanDetailContext from '../../context/loanDetailContext';
import query from '../../query';
import { useQuery } from '@apollo/client';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';

const AccountCard = props => {
  const LoanDetail = useContext(LoanDetailContext);

  const loanId = LoanDetail.loan_id;

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const { data } = useQuery(query.getLoanforAccountCard, {
    variables: { loanId: loanId },
  });
  const handleLinkClick = loanId => {
    props.handleLinkClick(loanId);
    toggle();
  };

  if (data) {
    var loan = data.loans[0]
  }

  const pastDueAmountToNumber = amount => {
    return Number(amount.replace(/\$|,/g, ''))
  }

  const handleScroll = () => {
    window.scrollTo(0, 400);
  }

  return (
    <div className="col-md-12 col-lg-5 col-12">
      {data && (
        <div className="shadow-custom account-sec-top">
          <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row">
            {props.loanList.length > 1 ? (
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                  caret
                >
                  <span className="account-num">Account </span>
                  <span className="account-num text-orange active"> #{loanId}</span>
                </DropdownToggle>
                <DropdownMenu>
                  <b>Select a different account</b>
                  <ul className="change-account-dropdown pl-0">
                    {props.loanList &&
                      props.loanList.map(loan => {
                        return (
                          <li key={loan.id}>
                            <h5
                              className="text-dark jeep-drop-heading mt-3"
                              onClick={() => handleLinkClick(loan.id)}
                            >
                              { loan.vehicle ? (
                                <div>
                                  <span className="text-theme"> #{loan.id}</span> -
                                  <b>
                                    {loan.vehicle.year} &nbsp;
                                    {loan.vehicle.make}&nbsp;
                                  </b>
                                  &nbsp;
                                  {loan.vehicle.model}
                                </div>
                              ) : (
                                <div>
                                  <span className="text-theme"> #{loan.id}</span> -
                                  <b>
                                    {loan.house.coveredAddress} &nbsp;
                                  </b>
                                </div>
                              )}
                            </h5>
                          </li>
                        );
                      })}
                  </ul>
                </DropdownMenu>
              </Dropdown>) : (
                <span className="account-num">Account #{loanId}</span>
              )}
          </div>
          {pastDueAmountToNumber(loan.pastDueAmount) !== 0 && (
            <div className="card-due-amount-warning">
              <b className="text-red">You have a past due amount of {loan.pastDueAmount}</b>
            </div>
          )}
          { loan.vehicle ? ( 
            <div>
              <h4 className="mt-1 mb-0">
                <b>{ loan.vehicle.make } </b> { loan.vehicle.model }
              </h4>
              <span>({loan.vehicle ? loan.vehicle.vin : ''})</span>
            </div>
          ) : (
            <h4 className="mt-1 mb-0">
              <b>{ loan.house.coveredAddress} </b> 
            </h4>
          )}
          {/* ///////// Amount Due ////////// */}
          {loan.nextPaymentDate === 'Paid' ? (
            <div className="amount-due">
              <h2 className="heading text-theme m-0">
                <b>{loan.currentDueBalance}</b>
              </h2>
              <span>Paid</span>
            </div>
          ) : (
            <React.Fragment>
              <div className="mt-2 d-flex link-box">
                {Number(loan.currentDueBalance.slice(1)) === 0 && loan.paymentPlanType === 'monthly'  ? (
                  <div className="amount-due">
                    <h2 className="heading-2 text-theme mb-3">
                      <b>No Amount Due</b>
                    </h2>
                  </div> ) : (
                  <div className="amount-due">
                    <h2 className="heading text-theme m-0">
                      <b>{loan.paymentPlanType === 'monthly' ? loan.currentDueBalance : loan.currentAutoDueBalance}</b>
                    </h2>
                    <span>Amount due on {loan.paymentPlanType === 'monthly' ? loan.nextPaymentDate : loan.nextSplitPaymentDate}</span>
                  </div>)
                  }
                  <div className="amount-due">
                    <h2 className="heading text-theme m-0">
                      <b>{loan.balance}</b>
                    </h2>
                    <span>Current Balance</span>
                  </div>
                </div>
              <div className="mt-2 d-flex link-box">
                { props.paymentWarning &&
                  <span className="mobile-view-link mr-2">
                    <b onClick={handleScroll}>
                      <Link to="/AccountSummary">
                        <u>Review Scheduled Payments</u>
                      </Link>
                    </b>
                  </span>}
                <Link to="/MakePayment" className="d-flex flex-column small-link invisible-on-mobile ml-2" onClick={handleScroll}>
                  <div className="small-icon"></div>
                  <span className="small-text">Make A Payment</span>
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountCard;
