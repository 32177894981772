import React, {useState, useContext, useEffect} from 'react';
import {Container, Form, Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import Logo from '../../components/logo/Logo';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import InputCheckBox from '../../components/inputCheckBox/InputCheckBox';
import Loader from '../../components/loader/Loader';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import AuthContext from '../../context/authContext';
import messages from '../../config/constant/messages';
import query from '../../query';
import ForgotPassword from './ForgotPassword';
import './login.scss';
import {useMutation} from '@apollo/client';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const Login = ({history, ...props}) => {
  const env = runtimeEnv();
  const {setUserAuthData} = useContext(AuthContext);

  const [rememberUser, setRememberUser] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [loginPageWarning, setLoginPageWarning] = useState();

  const [values, setValues] = useState({
    email: '',
    password: '',
    loggedIn: '',
    formErrors: {email: '', password: ''},
  });

  const [signInUser, {data: authenticationData, loading, error: authenticationError}] = useMutation(query.authentication, { errorPolicy: 'all'});

  useEffect(() => {
    if (authenticationError) {
      setLoginPageWarning({message: authenticationError.message, color: 'danger'});
    } else {
      clearForm();
    }

    if (authenticationData && authenticationData['signInUser']) {
      const { user, token } = authenticationData['signInUser'];

      if (user && token) setInfoAfterLogin(user, token);

      if (rememberUser) {
        userCredentials(user.email)
      }
    }
  }, [authenticationError, authenticationData])

  const setLiveChatUserInfo = (displayName, email, id) => {
    if (window.LiveChatWidget) {
      window.LiveChatWidget.call('set_customer_name', displayName)
      window.LiveChatWidget.call('set_customer_email', email)
    }
    if (window.Upscope) {
      window.Upscope('updateConnection', {
        identities: [displayName, email],
        uniqueId: id,
      });
    }
    if (window.__insp) {
      window.__insp.push(['tagSession', {userid: id, email: email}]);
    }
  };

  const setRollbarInfo = (displayName, email, id) => {
    if (window.Rollbar) {
      window.Rollbar.configure({
        payload: {
          person: {
            id: id,
            email: email,
            name: displayName,
            inspecletId: window.__insp && window.__insp.sid,
          },
        },
      });
    }
  };


  const setInfoAfterLogin = (user, token) => {
    if (user.dealerOrLine5) {
      window.location.replace(env.REACT_APP_CANONICAL_URL)
    } else {
      const {displayName, email, id} = user;
      setUserAuthData(token, displayName);
      setLiveChatUserInfo(displayName, email, id);
      setRollbarInfo(displayName, email, id);
    }
  }

  const handleSubmit = e => {
    e.preventDefault();
    if (formIsInvalid(values)) {
      setLoginPageWarning({message: messages.loginErrors.invalid, color: 'danger'});
      clearForm();
    }

    signInUser({
      variables: {
        email: values.email,
        password: values.password
      }
    })
  };

  const clearForm = () => {
    setValues({...values, email: '', password: ''});
  };

  const toggleModal = () => {
    setLoginPageWarning(false);
    setShowModal(!showModal);
  };

  const formIsInvalid = values => {
    return values.email === '' || values.password === '';
  };

  const handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;
    setValues({...values, [name]: value});
    let formErrors = values.formErrors;
    switch (name) {
      case 'email':
        formErrors.email = value.length < 1 ? 'Required' : '';
        break;
      case 'password':
        formErrors.password = value.length < 1 ? 'Required ' : '';
        break;
      default:
        break;
    }
  };

  const handleRememberMe = () => {
    setRememberUser(!rememberUser);
  };

  const userCredentials = (email) => {
    localStorage.setItem('username', email)
  }

  const handleForgotPasswordSuccess = values => {
    values.email = '';
    setLoginPageWarning({message: messages.forgotPasswordMessages.forgotPassSuccess, color: 'success'});
    clearForm();
    setShowModal(false);
  };

  useEffect(() => {
    if (localStorage.getItem('sessionExpired')) {
      setLoginPageWarning({message: messages.loginErrors.timeout, color: 'danger'});
      localStorage.removeItem('sessionExpired')
    }

    const checkForUrlToken = () => {
      const urlParams = new URLSearchParams(history.location.search)
      const token = urlParams.get('token')
      if (token) {
        localStorage.setItem('user_auth_data', token)
        signInUser({variables: {email: '', password: ''}})
      }
    };
    checkForUrlToken();
    if (localStorage.username) {
      setValues(values => ({...values, email: localStorage.username}))
    }
  }, [history.location.search])

  return (
    <div>
      <Container>
        <Form
          onSubmit={handleSubmit}
          className="py-1 py-lg-5 align-element-center"
        >
          <div className="col-lg-4 col-12 col-md-7 m-auto text-center">
            <Logo/>
            {loading && <Loader/>}
            {loginPageWarning && (
              <StatusMessage
                color={loginPageWarning.color}
                value={loginPageWarning.message}
                allowClose={false}
              />
            )}

            <div className="mt-1">
              <InputText
                type="email"
                name="email"
                id="email"
                placeholder="Enter Email"
                onChange={handleChange}
                value={values.email}
                errorsMessage={values.formErrors ? values.formErrors.email : ''}
                required={true}
              />
              <InputText
                type="password"
                name="password"
                id="password"
                placeholder="Enter Password"
                onChange={handleChange}
                value={values.password}
                errorsMessage={values.formErrors ? values.formErrors.password : ''}
                required={true}
              />
            </div>
            <Row className="justify-content-between">
              <div className="col-lg-5 col-5 col-md-5 text-left">
                <InputCheckBox
                  type="checkbox"
                  id="remember_me"
                  label=" Remember me"
                  onClick={handleRememberMe}
                />
              </div>
              <i className="forgot-pass col-5 col-md-5 text-right">
                <u onClick={toggleModal} className="openPopup">
                  Forgot password?
                </u>
              </i>
            </Row>
            <Row className="justify-content-between align-items-center mt-4 mt-lg-4 row">
              <div className="col-md-6 col-lg-6 col-5 text-left">
                <Link to="/findaccount">
                  <i>
                    <u className="new-customer">
                      <b>New Customer? </b>
                      <br/>
                      Activate Your Account
                    </u>
                  </i>
                </Link>
              </div>
              <Button type="submit" title="Login"></Button>
            </Row>
          </div>
        </Form>
      </Container>
      <ForgotPassword
        toggleModal={toggleModal}
        isModalOpen={showModal}
        handleSuccess={handleForgotPasswordSuccess}
      />
    </div>
  );
};

export default Login;
