import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import './inputSelect.scss';

const InputSelect = ({ type, name, id, className, options, onChange, value }) => {
  return (
    <FormGroup className="mb-lg-0 mb-sm-3">
      <Input
        type={type}
        name={name}
        id={id}
        className={'select-input ' + className}
        onChange={onChange}
        value={value}
      >
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.toLowerCase()}>
              {option}
            </option>
          ))}
      </Input>
    </FormGroup>
  );
};

export default InputSelect;
