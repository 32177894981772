import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import './inputText.scss';

const InputText = ({
  required,
  type,
  name,
  id,
  placeholder,
  className,
  value,
  onChange,
  errorsMessage,
  disabled,
  readonly,
  tag,
  mask,
  maskChar,
  autoComplete,
  ...rest
}) => {
  const styles = {
    inputText: {
      borderBottom: '3px solid #24272A',
    },
  };
  const isRequired = required === false ? false : true;
  return (
    <FormGroup className="">
      <Input
        required={isRequired}
        type={type}
        name={name}
        id={id}
        className={'input-type-text ' + className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={styles.inputText}
        disabled={disabled}
        readOnly={readonly}
        tag={tag}
        mask={mask}
        maskchar={maskChar}
        autoComplete={autoComplete}
        {...rest}
      />
      <div className="text-red mt-1">{errorsMessage}</div>
    </FormGroup>
  );
};

export default InputText;
