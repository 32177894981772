import React, { useContext, useState, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import './AccountSummary.scss';
import Autopay from '../autopayMethod/Autopay';
import AccountMenus from '../../components/accountMenus/AccountMenus';
import AccountSummaryComp from '../../components/accountSummaryComp/AccountSummaryComp';
import PaymentExpire from '../../components/paymentExpire/PaymentExpire';
import MyCoverage from '../myCoverages/MyCoverage';
import StatementNotification from '../statementAndNotification/StatementNotification';
import AccountCard from '../../components/AccountCard/AccountCard';
import LoanDetailContext from '../../context/loanDetailContext';
import AddingPaymentMethod from '../addingNewPaymentMethod/AddingPaymentMethod';
import query from '../../query';
import MakePayment from "../makePayment/MakePayment";
import Plaid from '../plaid/Plaid';

const AccountSummary = props => {
  const LoanDetail = useContext(LoanDetailContext);

  const handleLinkClick = id => {
    localStorage.removeItem('loan_id');
    localStorage.setItem('loan_id', id);
    LoanDetail.loan_detail();
  };

  const loanId = LoanDetail.loan_id;

  const [paymentWarning, setPaymentWarning] = useState(false);

  const [autopayExist, setAutopayExist] = useState(false);

  let content = null;
  switch (props.location.pathname) {
    case '/AccountSummary':
      content = (
        <AccountSummaryComp
          setPaymentWarning={setPaymentWarning}
        />
      );
      break;
    case '/PaymentMethods':
      content = (
        <Autopay />
      );
      break;
    case '/MakePayment':
      content = (
        <MakePayment />
      );
      break;
    case '/MyCoverage':
      content = <MyCoverage />;
      break;
    case '/Statements':
      content = <StatementNotification />;
      break;
    case '/PaymentMethods/AddingPaymentMethod':
      content = (
        <AddingPaymentMethod
          props={props}
          autopay={autopayExist}
        />
      );
      break;
    case '/PaymentMethods/Plaid':
        content = (
          <Plaid
            props={props}
          />
        );
        break;
    default:
      break;
  }

  const { data } = useQuery(query.fetchLoan, {
    variables: { loanId: loanId },
  });

  const [loanList, updateLoanList] = useState('');
  useEffect(() => {
    if (data) {
      updateLoanList(data.loans);

      let autoPayInfoArray = data.paymentMethods.filter(element => {
        return element.autopay === true;
      });
      autoPayInfoArray.length !== 0 && setAutopayExist(true);
    }
  }, [data]);

  return (
    <Container>
      <div className="align-element-top">
        <Row className={'mb-4'}>
          <AccountCard
            loanList={loanList}
            handleLinkClick={id => handleLinkClick(id)}
            paymentWarning={paymentWarning}
          />

          <div className="col-md-12 col-lg-7 col-12 payment-expire-block">
            <PaymentExpire className={props.location.pathname === '/AccountSummary' ? ' ' : 'invisible-in-mobile'}/>
          </div>
          <AccountMenus className="hideOnMobile" />
        </Row>
        {content}
      </div>
    </Container>
  );
};
export default AccountSummary;
