import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './TooltipBubble.scss';

const TooltipBubble = ({ placement, target, value }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip placement={placement} isOpen={tooltipOpen} target={target} toggle={toggle}>
      {value}
    </Tooltip>
  );
};

export default TooltipBubble;
