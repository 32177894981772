import React from 'react';
import messages from "../../config/constant/messages";
import StatusMessage from "../../components/statusMessage/StatusMessage";
import PaymentInfoBox from "./PaymentInfoBox";
import Button from "../../components/button/Button";

export const PaymentConformation = ({
                                      paymentData,
                                      currentPaymentMethod,
                                      cancelNextScheduledPaymentStatus,
                                      setFormStep
                                    }) => {
  let messageValue = `A payment of amount of $${paymentData['amount']} was scheduled on ${paymentData['appliedOn']}`

  if (paymentData['scheduledPayment']) {
    return (
      <>
        <StatusMessage color={'success'} value={messageValue} allowClose={true}/>
        <Button title={'Make another Payment'} onClick={() => setFormStep(0)}/>
      </>
    )
  } else {
    return (
      <>
        <StatusMessage color={'success'} value={currentPaymentMethod['transactionType'] == 'creditcard' ? messages.paymentCreditCardSuccess : messages.paymentSuccess} allowClose={true}/>
        <PaymentInfoBox paymentInfoValues={{
          date: paymentData['appliedOn'],
          amount: paymentData['amount'],
          paymentMethod: `${currentPaymentMethod.cardType || 'ACH'} **** ${currentPaymentMethod.lastFour}`,
          principleAndInterestAmount: paymentData['principalAndInterestPaid'],
          feesAmount: paymentData['fees'],
          convenienceFee: paymentData['convenienceFee']
        }}
                        cancelNextScheduledPayment={cancelNextScheduledPaymentStatus}
                        showMakePaymentBtn={true}
                        handleNewPayment={() => setFormStep(0)}
        />
      </>
    )
  }
}