import React, { useState, useEffect } from 'react';
import Logo from '../../components/logo/Logo';
import { Container, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import Button from '../../components/button/Button';
import InputText from '../../components/inputText/InputText';
import query from '../../query';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import messages from '../../config/constant/messages';
import ForgotPassword from '../userLogin/ForgotPassword';

const PasswordReset = (props) => {
  const [token, setToken] = useState('')
  const [resetError, setResetError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [modalOpen, toggleModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const [values, setValues] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [check, toggleCheck] = useState({
    length: 'password-required',
    number: 'password-required',
    letter: 'password-required',
  });

  const [formErrors, setFormError] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const validatePassword = (value) => {
    const letter = new RegExp(/(.*[A-Z].*)$/);
    const number = new RegExp(/[0-9]/);
    const newCheck = {}

    newCheck['length'] = value.length >= 8 ? 'password-required-tick' : 'password-required'
    newCheck['letter'] = letter.test(value) ? 'password-required-tick' : 'password-required'
    newCheck['number'] = number.test(value) ? 'password-required-tick' : 'password-required'

    toggleCheck({ ...check, ...newCheck });
  };

  const handleChange = e => {
    e.preventDefault();
    setResetError(null)
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    let formError = formErrors;
    switch (name) {
      case 'password':
        formError.password = value.length < 1 ? 'required' : '';
        validatePassword(value);
        break;
      case 'passwordConfirmation':
        formError.passwordConfirmation =
          value !== values.password
            ? "Password confirmation doesn't match Password"
            : '';
        break;
      default:
        break;
    }
    setFormError(formError);
  };

  useEffect(() => {
    const setResetToken = () => {
      const params = new URLSearchParams(props.location.search);
      setToken(params.get('reset_password_token'))
    };
    setResetToken()
  }, [props.location])

  const onSuccessfulReset = (token) => {
    setSuccess(true)
    setSuccessMessage(messages.resetPassword.success)
    setTimeout(() => {
      props.history.push('/login?token=' + token)
    }, 1000 * 1.5);
  }

  const tokenError = () => {
    return resetError && resetError.join(',').includes('token is invalid')
  }

  const handleForgotPasswordSuccess = () => {
    setSuccess(true)
    setResetError(null)
    setSuccessMessage(messages.forgotPasswordMessages.forgotPassSuccess)
    toggleModal(false);
  }

  return (
    <Container>
      {/* start form */}
      <Mutation mutation={query.resetPassword}>
        {(resetPassword, { data, loading, error }) => (
      <div className="align-element-center">
        <Form
          className="col-md-12 col-lg-12  py-1 py-lg-5"
          onSubmit={async e => {
            e.preventDefault()
            try {
              const { data: MutationData  } = await resetPassword({
                variables: {
                  password:             values.password,
                  passwordConfirmation: values.passwordConfirmation,
                  currentPassword:      values.currentPassword,
                  resetPasswordToken:   token
                }
              })

              if(MutationData){
                onSuccessfulReset(MutationData.resetPassword.token)
              }
            } catch (e) {
              if (e.graphQLErrors) {
                const error = e.graphQLErrors.map(e => e.message)
                try {
                  const arrayOfErrors = JSON.parse(error[0])
                  setResetError(arrayOfErrors)
                } catch (error) {
                  setResetError(['Error: Please try again.'])
                }
              }
            }
          }}
        >
          <div className="col-12 col-md-12 col-lg-12 mt-4">
            {/* Logo component */}
            <Logo />
          </div>
          <Row>
            <Col sm="12" md={{ size: 8 }}>
              <div className="col-md-8 col-12 col-lg-8 text-left ">
                <div className="mt-5">
                  <h3 className="heading mb-0">
                    <b>Set your password</b>
                  </h3>
                  <span className="below-headint-text">Create a Secure Password</span>
                </div>
                {resetError && (
                  resetError.map((e, index) => (
                    <StatusMessage
                      key={index}
                      color='danger'
                      value={e}
                      allowClose={false}
                      className="modal-s-error"
                    />
                  ))
                )}
                {tokenError() && (
                  <u onClick={() => toggleModal(true)} className="openPopup">
                    Click to send new token
                  </u>
                )}
                {/* Input Components */}
                { success && (
                  <StatusMessage
                    color='success'
                    value={successMessage}
                    allowClose={false}
                    className="modal-s-error"
                  />
                )}
                { !success && (
                <>
                <div className="mt-1">
                  <InputText
                    type="password"
                    name="password"
                    id="password"
                    placeholder="New Password"
                    value={values.password}
                    onChange={handleChange}
                    className="text-font-size"
                    errorsMessage={formErrors ? formErrors.password : ''}
                  />
                  <InputText
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder="Confirm New Password"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    className="text-font-size"
                    errorsMessage={formErrors ? formErrors.passwordConfirmation : ''}
                  />
                </div>
                <div className="col-md-12 col-lg-12 col-12">
                  <Row className="justify-content-end align-items-center mt-4 mt-lg-5">
                    <div className=" mr-4">
                      <Link to="/" title="Cancel" className="cancel-link">
                        Cancel
                      </Link>
                    </div>
                    <div className="login-btn ml-2">
                      <Button title="Create Password" hvrtitle="Create Password"></Button>
                    </div>
                  </Row>
                </div>
                </>
                 )}
              </div>
            </Col>
            {/* Password requirement */}
            <Col sm="12" md={{ size: 4 }}>
              <div className="col-md-12 col-12 text-left">
                <div className="mt-4">
                  <h3 className="heading">
                    <b>Password Requirements</b>
                  </h3>
                  <label className={'below-headint-text pl-5 ' + check.length} id="length">
                    Must have at least 8 characters
                  </label>
                  <br />
                  <label className={'below-headint-text pl-5 ' + check.letter} id="letter">
                    Must contain at least one capital letter
                  </label>
                  <br />
                  <label className={'below-headint-text pl-5 ' + check.number} id="number">
                    Must have at least one number
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
         )}
      </Mutation>
      <ForgotPassword
        toggleModal={() => toggleModal(!modalOpen)}
        isModalOpen={modalOpen}
        handleSuccess={handleForgotPasswordSuccess}
      />
    </Container>
  );
};

export default PasswordReset;
