import React, {useState, useImperativeHandle, createRef, useEffect} from "react";
import AuthContext from './authContext';

export const AuthContextRef = createRef();

const AuthContextProvider = ({children}) => {
  const [authStatus, setAuthStatus] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    setToken(localStorage.getItem('user_auth_data'))
  }, [])

  useEffect(() => {
    if (localStorage.getItem('user_auth_data')) {
      setAuthStatus(true);
    } else {
      logout();
    }
  }, [token]);

  const login = () => {
    setAuthStatus(true);
  };

  const logout = () => {
    setAuthStatus(false);
    setToken('');
    localStorage.removeItem('loan_id');
    localStorage.removeItem('user_auth_name');
    localStorage.removeItem("user_auth_data");
    localStorage.removeItem("username");
  };

  const setUserAuthData = (token, displayName) => {
    localStorage.setItem('user_auth_data', token);
    localStorage.setItem('user_auth_name', displayName);
    setToken(token);
  }

  const contextValue = {
    status: authStatus,
    login,
    logout,
    setUserAuthData
  }

  useImperativeHandle(AuthContextRef, () => contextValue);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
