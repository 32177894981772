import React, { useContext} from "react";
import { Redirect, Route } from "react-router-dom";
import authContext from "../context/authContext";

export default function PrivateRoute (props) {
  const auth = useContext(authContext);

  if (auth.status) {
    return <Route {...props} />;
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: props.location.pathname },
        }}
      />
    );
  }
}
