import React, { useState, useContext, useEffect } from 'react';
import { Row } from 'reactstrap';
import './AccountSummaryComp.scss';
import { Table, Thead, Tbody, Th, Tr, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import TooltipBubble from '../tooltip/TooltipBubble';
import Loader from '../../components/loader/Loader';
import NoDataFound from '../../components/noDataFound/noDataFound';
import { useQuery } from '@apollo/client';
import LoanDetailContext from '../../context/loanDetailContext';
import query from '../../query';
import { Mutation } from '@apollo/react-components';
import { Form } from 'reactstrap';
import Button from '../../components/button/Button';
import { Modal, ModalBody } from 'reactstrap';
import StatusMessage from '../../components/statusMessage/StatusMessage';
import messages from '../../config/constant/messages';

const Home = ({ setPaymentWarning }) => {

  const loanDetail = useContext(LoanDetailContext);

  const loanId = loanDetail.loan_id;

  const changeDateFormat = date => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    return [year, month, day].join('-').toString();
  };

  const [filterDate, setFilterDate] = useState({
    startDate: '',
    endDate: '',
  });

  const { loading, data, refetch } = useQuery(query.getPayments, {
    variables: {
      loanId: loanId,
      startDate: filterDate.startDate,
      endDate: filterDate.endDate,
    },
    fetchPolicy: 'network-only',
  });

  const [dateRangeOn, isDateRangeOn] = useState({
    toggleModel: true,
  });

  const [values, setValues] = useState({
    collapsed: false,
    paymentData: [],
    loanData: [],
    date: [new Date(), new Date()],
    filter: '1',
  });

  const [paymentRecord, updatePaymentRecord] = useState('');

  const [loanRecord, updateLoanRecord] = useState('');

  const [lastPayment, updateLastPayment] = useState('');

  const [recordToDelete, updateRecordToDelete] = useState('');

  const [statusMessage, setStatusMessage] = useState(null);

  const [showDeleteModal, setDeleteModal] = useState(false);

  const deleteOn = payment_record => {
    updateRecordToDelete(payment_record);
    setDeleteModal(!showDeleteModal);
  };

  //Method to retrieve the default payment list
  useEffect(() => {
    if (data) {
      updateLoanRecord(data.loans[0]);
      let paymentArray = data.payments;

      // last approved payment as an object
      const lastApprovedPayment = paymentArray.find(({ status }) => status === 'Approved');
      paymentArray.length !== 0 && updateLastPayment(lastApprovedPayment);

      let payment_record = '';

      if (values.filter === '1' && paymentArray.length !== 0) {
        payment_record = paymentArray;
      } else {
        payment_record = data.payments;
      }
      updatePaymentRecord(payment_record);

      if (
        paymentArray.find(
          ({ status, description }) => status === 'Pending' && description === 'Scheduled Payment'
        )
      ) {
        setPaymentWarning(true);
      }
    }
  }, [data, values, setPaymentWarning]);

  if (loading) return <Loader />;

  // if (error) {
  //   localStorage.removeItem('user_auth_data');
  //   let message = 'error.networkError.result.error.message';
  //   auth.login();
  //   errorMessage.error(message);
  //   return (
  //     <Redirect
  //       to={{
  //         pathname: '/',
  //       }}
  //     />
  //   );
  // }

  //Method to retrive the payment list according to the applied filter
  const changePayments = e => {
    let days = e.target.value;
    let startDate = new Date();
    let endDate = new Date();
    endDate = changeDateFormat(endDate);
    switch (days) {
      case '1':
        startDate = '';
        endDate = '';
        break;

      case '2':
        startDate.setMonth(startDate.getMonth() - 6);
        startDate = changeDateFormat(startDate);
        break;

      case '3':
        startDate.setMonth(startDate.getMonth() - 12);
        startDate = changeDateFormat(startDate);
        break;
      default:
        dateRangeToggle();
    }

    setFilterDate({ startDate: startDate, endDate: endDate });
    setValues({ ...values, filter: days });
  };

  //Method to toggle the filter option
  const filterToggle = () => {
    if (values.filter !== '4') {
      isDateRangeOn(toggleM => ({
        toggleModel: true,
      }));
    }
    setValues({ ...values, date: [new Date(), new Date()] });
  };

  //Method to toggle the DateRange option
  const dateRangeToggle = () => {
    isDateRangeOn(toggleM => ({
      toggleModel: false,
    }));
  };

  // Method for expandable table row
  const toggleTable = openIndex => {
    let data = document.getElementById('record' + openIndex);
    let smData = document.getElementById('smRecord' + openIndex);
    let icon = document.getElementById('show_more' + openIndex);
    if (data.classList.contains('hide')) {
      data.classList.replace('hide', 'show');
      smData.classList.replace('hide-sm', 'show-sm');
      icon.classList.replace('fa-question', 'fa-times');
      icon.classList.replace('question-icon', 'cross-icon');
    } else if (data.classList.contains('show')) {
      closeRow(data, smData, icon);
    }
    paymentRecord.map((item, index) => {
      if (index !== openIndex) {
        let data = document.getElementById('record' + index);
        let smData = document.getElementById('smRecord' + index);
        let icon = document.getElementById('show_more' + index);
        closeRow(data, smData, icon);
      }
      return true;
    });
  };

  // Method to close row
  const closeRow = (data, smData, icon) => {
    data.classList.replace('show', 'hide');
    smData.classList.replace('show-sm', 'hide-sm');
    icon.classList.replace('fa-times', 'fa-question');
    icon.classList.replace('cross-icon', 'question-icon');
  };

  // Method to retrieve the data when date Range is selected from the calendar
  const onChange = date => {
    setValues({ ...values, date: date });
    if (date !== null) {
      let startDate = changeDateFormat(date[0]);
      let endDate = changeDateFormat(date[1]);
      setFilterDate({ startDate: startDate, endDate: endDate });
    }
  };

  // Method to slice the string to get desired date format
  const dateSlice = dateString => {
    let paymentDate;
    dateString ? (paymentDate = dateString) : (paymentDate = '');
    if (paymentDate !== '') {
      paymentDate = paymentDate.slice(0, 12);
    }
    return paymentDate;
  };

  //Method to get the formatted date
  const getDateFormat = nextPaymentDate => {
    return new Date(nextPaymentDate).toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const handleDelete = async (e, deleteScheduledPayment) => {
    e.preventDefault();
    try {
      const { data: mutationData } = await deleteScheduledPayment({
        variables: {
          paymentId: parseInt(recordToDelete.id),
        },
      });
      if (mutationData) {
        refetch();
        setDeleteModal(!showDeleteModal);
        setStatusMessage({
          message: messages.scheduledPaymentDelete,
          color: 'success',
          allowClose: false,
          timeout: true,
        });
        setPaymentWarning(false);
      }
    } catch (err) {
      setStatusMessage({
        message: err.graphQLErrors.length
          ? err.graphQLErrors.map(e => e.message)
          : messages.default,
        color: 'danger',
        allowClose: true,
        timeout: false,
      });

      setDeleteModal(!showDeleteModal);
    }
  };

  //table header value
  const tableHeader = [
    {
      value: 'Payment Date',
      class: '',
    },
    {
      value: 'Amount',
      class: '',
    },
    {
      value: 'Payment Method',
      class: '',
    },
    {
      value: 'Type',
      class: '',
    },
    {
      value: 'Status',
      class: '',
    },
    {
      value: '',
      class: '',
    },
  ];

  const cancelLinkShow = payment => {
    var pending = payment.description === 'Scheduled Payment' && payment.status === 'Pending';
    var processing = payment.description === 'Scheduled Payment' && payment.status === 'Processing';
    if (pending || processing) {
      return 'active';
    } else {
      return 'hide';
    }
  };

  return (
    <Row className="centered">
      <div className="w-100">
        <h2 className="heading">
          <b>Account Summary</b>
        </h2>
      </div>
      <div className="w-100">
        {statusMessage && (
          <StatusMessage
            color={statusMessage.color}
            value={statusMessage.message}
            allowClose={statusMessage.allowClose}
            className="mt-3"
            timeout={statusMessage.timeout}
          />
        )}
      </div>
      <div className="w-100 d-lg-flex">
        {lastPayment && (
          <div className="col-md-4 col-lg-3 col-12 my-2 my-md-4 account-sec-top shadow-custom custom-height">
            <h6 className="below-headint-text">
              <b>Date of Last Payment</b>
            </h6>
            <h3 className="mb-0 greeting-heading">{dateSlice(lastPayment.appliedDate)}</h3>
            <span className="payment-of">Payment of {lastPayment.amount}</span>
          </div>
        )}

        {loanRecord && (
          <div className="col-md-4 col-lg-3 col-12 my-2 my-md-4 ml-lg-2 account-sec-top shadow-custom custom-height">
            <h6 className="below-headint-text">
              <b>Next Payment Due</b>
            </h6>
            {loanRecord.nextPaymentDate !== 'Paid' ? (
              <React.Fragment>
                <h3 className="mb-0 greeting-heading">
                  {loanRecord.paymentPlanType === 'monthly' ? getDateFormat(loanRecord.nextPaymentDate) : getDateFormat(loanRecord.nextSplitPaymentDate) }
                </h3>

                <span className="payment-of">Payment of {loanRecord.paymentPlanType === 'monthly' ? loanRecord.monthlyPayment : loanRecord.currentAutoDueBalance}</span>
              </React.Fragment>
            ) : (
              <h3 className="mb-0 greeting-heading">{loanRecord.nextPaymentDate}</h3>
            )}
          </div>
        )}
      </div>
      {/* end account summary section */}

      <div className="col-md-12 col-lg-12 col-12 my-4 pr-0 pl-0">
        <div className="d-block d-md-flex  align-items-center">
          <h3>
            <b>Payment History</b>
          </h3>
          <div className="history-filter ">
            <select
              className={'filter-box'}
              id="filter"
              onChange={changePayments}
              onClick={filterToggle}
              value={values.filter}
            >
              <option value="1"> All</option>
              <option value="2"> Last Six Months</option>
              <option value="3"> Last One Year</option>
              <option value="4"> Custom Date</option>
            </select>
            <TooltipBubble placement="top" value="Filter Payment History" target="filter" />
          </div>
          <div className={!dateRangeOn.toggleModel ? 'history-filter' : 'history-filter invisible'}>
            <DateRangePicker onChange={onChange} value={values.date} maxDate={new Date()} clearIcon={null}/>
          </div>
          {/* end payment history filter section */}
        </div>
        <div className="mb-2">
          <div className="mt-3">
            <fieldset>
              <legend>
                <b>Payments</b>
              </legend>
              <div className="table-responsive scrolly">
                {paymentRecord && paymentRecord.length !== 0 ? (
                  <Table
                    className="payment-history-table table table-condensed py-0 py-lg-4 m-0 mt-lg-2"
                    id="toggleTable"
                  >
                    <Thead>
                      <Tr>
                        {tableHeader.map((header, index) => (
                          <Th className={header.class} key={index}>
                            {header.value}
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody className="panel">
                      {/* expandable table row */}
                      {paymentRecord.map((payment, index) => (
                        <React.Fragment key={index}>
                          <Tr className={index % 2 === 0 ? 'odd-row' : 'even-row'}>
                            <Td>{dateSlice(payment.appliedDate)}</Td>
                            <Td className="d-flex">
                              <i
                                onClick={() => toggleTable(index)}
                                className="fa fa-question question-icon"
                                id={'show_more' + index}
                              />
                              <TooltipBubble
                                placement="top"
                                value="Show Payment Details"
                                target={'show_more' + index}
                              />
                              {payment.amount}
                            </Td>
                            <Td>{payment.paymentMethod}</Td>
                            <Td>{payment.description}</Td>
                            <Td>{payment.status}</Td>
                            <Td className="card-delete-icon text-center">
                              <div
                                title="Cancel"
                                className={cancelLinkShow(payment)}
                                onClick={() => deleteOn(payment)}
                              >
                                <span>Cancel</span>
                              </div>
                            </Td>
                            <Td className="mobile-td-only smRecord">
                              <div className="hide-sm fees-all" id={'smRecord' + index}>
                                <div>
                                  <b>Principal:</b>
                                  <br /> {payment.principal}
                                </div>
                                <div>
                                  <b>Interest:</b>
                                  <br /> {payment.interest}
                                </div>
                                <div>
                                  <b>Convenience Fees:</b>
                                  <br />
                                  <i
                                      className="fa fa-question question-icon"
                                      id={`payment-fees-more-info-${index}`}
                                  />
                                  <TooltipBubble
                                      placement="top"
                                      value={`A convenience fee of ${payment.convenienceFees} is added to debit or credit card payments.  There is no convenience fee for checking account ACH payments.`}
                                      target={`payment-fees-more-info-${index}`}
                                  />
                                  {payment.convenienceFees}
                                </div>
                                <div>
                                  <b>Late Fees:</b>
                                  <br /> {payment.lateFees}
                                </div>
                                <div>
                                  <b>Return Fees:</b>
                                  <br /> {payment.nsfFees}
                                </div>
                                <div colSpan="100%">
                                  <b>Document Fees:</b>
                                  <br /> {payment.documentFees}
                                </div>
                              </div>
                            </Td>
                          </Tr>
                          <Tr
                            className="d-none-mobile bg-gh-white odd-row hide new"
                            id={'record' + index}
                          >
                            <Td>
                              <b>Principal:</b>
                              <br /> {payment.principal}
                            </Td>
                            <Td>
                              <b>Interest:</b>
                              <br /> {payment.interest}
                            </Td>
                            <Td>
                              <b>Convenience Fees:</b>
                              <br />
                              <i
                                className="fa fa-question question-icon"
                                id={`payment-fees-more-info-${index}`}
                              />
                              <TooltipBubble
                                placement="top"
                                value={`A convenience fee of ${payment.convenienceFees} is added to debit or credit card payments.  There is no convenience fee for checking account ACH payments.`}
                                target={`payment-fees-more-info-${index}`}
                              />
                              {payment.convenienceFees}
                            </Td>
                            <Td>
                              <b>Late Fees:</b>
                              <br /> {payment.lateFees}
                            </Td>
                            <Td>
                              <b>Return Fees:</b>
                              <br />
                              {payment.nsfFees}
                            </Td>
                            <Td colSpan="100%">
                              <b>Document Fees:</b>
                              <br /> {payment.documentFees}
                            </Td>
                          </Tr>
                        </React.Fragment>
                      ))}
                      {/* end expandable table row */}
                    </Tbody>
                  </Table>
                ) : (
                  // end payment history table section
                  <NoDataFound />
                )}
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      {/* //Delete model */}
      <Modal isOpen={showDeleteModal} className="modal-s">
        <ModalBody className="mt-3 ml-5 mr-5 pb-2">
          <h4>
            <b>Are you sure you would like to delete this payment?</b>
          </h4>
          <div className="text-center">
            <i className="delete-card-name">
              <b></b>
            </i>
          </div>
        </ModalBody>
        <Mutation mutation={query.deleteScheduledPayment}>
          {(deleteScheduledPayment, { data, loading, error }) => (
            <div className="d-flex justify-content-center footer-model align-items-center">
              <span className="cancel-link mr-4" onClick={deleteOn}>
                Cancel
              </span>
              {loading && <Loader />}
              <Form onSubmit={e => handleDelete(e, deleteScheduledPayment)}>
                <Button
                  type="submit"
                  title="Delete"
                  hvrtitle="Delete"
                  className="alert-button m-0 text-white"
                />
              </Form>
            </div>
          )}
        </Mutation>
      </Modal>
    </Row>
  );
};

export default Home;
