import React, { useContext} from "react";
import { Redirect, Route } from "react-router-dom";
import authContext from "../context/authContext";

export default function PublicRoute(props) {
  const auth = useContext(authContext);

  if (auth.status) {
    return (
      <Redirect to="/" />
    );
  } else {
    return <Route {...props} />;
  }
}
