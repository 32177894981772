import React from 'react';

const Menu = props => {
  // inline style
  const styles = {
    container: {
      position: 'fixed',
      right: 0,
      height: 'auto',
      width: '280px',
      display: 'flex',
      flexDirection: 'column',
      background: '#ededed',
      opacity: 0.95,
      color: '#111',
      transition: 'height 0.3s ease',
      zIndex: 3,
      textAlign: 'right',
    },
    menuList: {
      paddingTop: '0.5rem',
    },
  };

  return (
    <div style={styles.container} className="side-navbar">
      {props.open ? (
        <div style={styles.menuList} ref={props.wrapperRef}>
          {props.children}
        </div>
      ) : null}
    </div>
  );
};

export default Menu;
