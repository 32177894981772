import React, { useState } from 'react';
import './AddingPaymentMethod.scss';
import { TabContent, TabPane, Row } from 'reactstrap';
import BankTransferForm from "../../components/paymentMethodForm/BankTransferForm";
import PaymentCardForm from "../../components/paymentMethodForm/PaymentCardForm";
import { Link, useLocation } from 'react-router-dom';
import StatusMessage from '../../components/statusMessage/StatusMessage';


const AddingPaymentMethod = props => {
  const location = useLocation();
  const [showPaymentMethodManually, setShowPaymentMethodManually] = useState(false);
  const [plaidError, setPlaidError] = useState();

  React.useEffect(() => {
    window.scrollTo(0, 300);
    if(location.state){
      if(location.state.showManually){
        setShowPaymentMethodManually(location.state.showManually);
      }
      if(location.state.errorMessage){
        setPlaidError(location.state.errorMessage);
      }      
    }
  }, []);

  const [tabs, isOpenTabs] = useState({
    activeTab: '1',
  });

  const [paymentMethod, setPaymentMethod] = useState({
    method: '',
  });

  const addPaymentManually = data =>{
    setPaymentMethod({method: data.method});
    isOpenTabs({
      activeTab: data.tab,
    });
  }

  return (
    <div>
      <h2 className="heading mt-2">
        <b>Add a new payment method</b>
      </h2>
      <br/>
      {plaidError && <StatusMessage
        color={'danger'}
        value={plaidError}
        allowClose={true}
        className="d-flex"
      />}
      <TabContent activeTab={tabs.activeTab} className="tab-contents">
        <TabPane tabId="1">
          <Row>
            <div className="col-md-12 col-lg-12">
              <Link to="/PaymentMethods/Plaid">
                <div className="orange-container">
                  <div>
                    <div className="image-container">
                      <div className="white-circle">
                        <div>
                          <img
                            src="https://cdn-icons-png.flaticon.com/512/15/15874.png"
                            className="phone-icon"
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className="text-click-here">Click Here</h3>
                  </div>
                </div>
              </Link>
            </div>
            {showPaymentMethodManually && <div className="col-md-12 col-lg-12">
              <div className="container-arrow-button" onClick={() => addPaymentManually({method: 'bank_transfer', tab: '2'})}>
                <div className="text-arrow">
                  <p>ADD PAYMENT METHOD MANUALLY</p>
                </div>
                <div className="image-div-arrow">
                  <img 
                    src="https://cdn-icons-png.flaticon.com/512/664/664866.png" 
                    className="img-arrow"
                  />
                </div>
            </div>
          </div>}
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            {paymentMethod.method === 'bank_transfer' && showPaymentMethodManually && <BankTransferForm addPaymentManually={addPaymentManually} />}
            {paymentMethod.method === 'payment_card' && showPaymentMethodManually && <PaymentCardForm addPaymentManually={addPaymentManually}/>}
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default AddingPaymentMethod;
