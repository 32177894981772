import React, { useState, useContext, useEffect } from 'react';
import './Plaid.scss';
import { useHistory } from 'react-router-dom';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {useMutation} from "@apollo/client";
import query from "../../query";
import LoanDetailContext from "../../context/loanDetailContext";
import { usePlaidLink } from 'react-plaid-link';
import PaymentDetailContext from '../../context/paymentDetailContext';
import Loader from '../../components/loader/Loader';

const Plaid = props => {
  const history = useHistory();
  const env = runtimeEnv();
  const [linkToken, setLinkToken] = useState(undefined);
  const {loan_id: loanId} = useContext(LoanDetailContext);
  const { updatePaymentData, updateMessage } = useContext(PaymentDetailContext);


  const [createLinkToken, {loading}] = useMutation(query.createLinkToken);
  const [registerPlaidPublicToken, {
    data: registerData,
    loading: registerLoading,
    error: registerError
  }] = useMutation(query.registerPlaidPublicToken);

  const onSuccess = async (publicToken, metadata) => {
    registerPlaidPublicToken(
      {variables: {
        loanId: parseInt(loanId),
        publicToken: publicToken,
        linkToken: linkToken
      }})
      .then(result => {
        if (result['data']['registerPlaidPublicToken']['paymentMethods']) {
          updateMessage('Payment Method Added To Your Account!')
          updatePaymentData({paymentMethodId: result['data']['registerPlaidPublicToken']['paymentMethods'][0]['id']});
          history.push("/MakePayment");
        }
      })
      .catch(error => {
        console.log(error, error.message)
        history.push({
          pathname: '/PaymentMethods/AddingPaymentMethod',
          state: {  
            errorMessage: error.message, 
            showManually: true, 
          }});
      });  
  }
  
  const onExit = async (error, metadata) => {
    history.push({
      pathname: '/PaymentMethods/AddingPaymentMethod',
      state: {
        showManually: true, 
      }});
  }

  useEffect(() => {
    window.scrollTo(0, 300);
    createLinkToken(
      {variables: {
        loanId: parseInt(loanId)
      }})
      .then(result => {
        setLinkToken(result['data']['createPlaidLinkToken']['linkToken']);
      })
      .catch(error => {
        history.push({
          pathname: '/PaymentMethods/AddingPaymentMethod',
          state: {  
            errorMessage: error.message, 
            showManually: true, 
          }});
      });   
  }, []);

  const config = {
    onSuccess,
    onExit,
    token: linkToken,
    env: env.PLAID_ENVIRONMENT
  }
  const { open, ready } = usePlaidLink(config);

  React.useEffect(() => {
    if (ready) {
      open();
    }
  }, [ready, open, props.isOauth, props.userId, props.itemId, linkToken]);

  return (
    <>
                    {(loading || registerLoading) && <Loader />}
    </>
  );
};

export default Plaid;
