import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import './inputCheckBox.scss';

const InputCheckBox = ({ type, label, id, onClick }) => {
  return (
    <FormGroup>
      <div>
        <CustomInput type={type} id={id} label={label} inline onClick={onClick} />
      </div>
    </FormGroup>
  );
};

export default InputCheckBox;
